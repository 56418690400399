import { InputBase, Box, styled } from '@mui/material'
import { Record } from '@tunasong/icons'
import type { Track } from '@tunasong/schemas'
import { disableAutocomplete, getColorCSS, useCachedValue } from '@tunasong/ui-lib'
import type { ChangeEvent, FC, MouseEvent } from 'react'
import React, { useCallback } from 'react'
import { useMixer } from '../../hooks/index.js'
import { Meter } from '../../meter.js'
import { useTrackControl } from '../hooks/track-control.js'
import { ChannelButton } from './channel-button.js'
import { ChannelPan } from './channel-pan.js'
import { ChannelVolume } from './channel-volume.js'

export interface ChannelProps {
  className?: string
  track: Track
  selected?: boolean
  onClick?(ev: MouseEvent): void
  onChange?(track: Track): void
}

interface ChannelContainerProps {
  isSelected?: boolean
}

const ChannelContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isSelected',
})<ChannelContainerProps>(({ theme, isSelected }) => ({
  fontSize: 12,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderLeftStyle: 'solid',
  borderLeftWidth: theme.spacing(0.5),
  justifyContent: 'space-evenly',
  userSelect: 'none',
  ...(isSelected && {
    backgroundColor: theme.vars.palette.action.hover,
  }),
}))

const ChannelRow = styled('div')(({ theme }) => ({
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const Channel: FC<ChannelProps> = props => {
  const { className, track, selected, onChange, onClick } = props
  const color = track.color ?? 'grey'
  const mixer = useMixer()

  const onUpdateTrack = (props: Partial<Track>) => onChange && onChange({ ...track, ...props })
  const { toggleMute, toggleSolo, toggleArm, onChangeVolume, onChangePan, pan, volume, channel } = useTrackControl({
    track,
    onUpdate: onUpdateTrack,
  })

  const [name, setName] = useCachedValue(track.name)
  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      () =>
        setName(value),
    [setName]
  )
  const handleName = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onChange && onChange({ ...track, name: ev.target.value ?? 'Untitled' }),
    [onChange, track]
  )

  /** @todo we need a better way to route input */
  const recordInput = mixer?.getBus('mic')

  return (
    <ChannelContainer sx={{ color: getColorCSS(color) }} className={className} isSelected={selected} onClick={onClick}>
      <ChannelRow>
        <Box
          sx={
            {
              // No specific styles needed
            }
          }
        >
          <ChannelButton selected={channel?.muted} onClick={toggleMute}>
            M
          </ChannelButton>
          <ChannelButton selected={channel?.solo} onClick={toggleSolo}>
            S
          </ChannelButton>
          <ChannelButton selected={channel?.armed} onClick={toggleArm}>
            <Record />
          </ChannelButton>
        </Box>
        <InputBase
          slotProps={{
            input: {
              sx: {
                fontSize: 12,
                padding: 0,
                textAlign: 'right',
              },
            },
          }}
          value={name}
          onChange={handleChange}
          onBlur={handleName}
          inputProps={disableAutocomplete}
        />
      </ChannelRow>
      <ChannelRow>
        <ChannelVolume volume={volume} onChangeVolume={onChangeVolume} />
      </ChannelRow>
      <ChannelRow>
        <ChannelPan pan={pan} onPan={onChangePan} />
      </ChannelRow>
      {channel?.armed && recordInput && (
        <ChannelRow>
          <Meter
            sx={{
              height: theme => theme.spacing(),
            }}
            controller={recordInput}
          />
        </ChannelRow>
      )}
    </ChannelContainer>
  )
}

export default Channel
