import { Box } from '@mui/material'
import type { AudioMedia } from '@tunasong/schemas'
import { isAudioClip } from '@tunasong/schemas'
import React from 'react'
import { usePeaks } from '../../hooks/index.js'
import { Wave } from '../../waveform/wave.js'
import { type ClipProps } from './clip-container.js'
import { WaveContainer } from './clip.styles.js'

export interface AudioClipProps extends ClipProps {
  className?: string
  ref?: React.Ref<HTMLDivElement>
}

const AudioClip = (props: AudioClipProps) => {
  const { className, clip, ref } = props

  if (!isAudioClip(clip)) {
    throw new Error(`AudioClip used for different clip type ${clip.type}`)
  }

  /** @todo  */
  const media: AudioMedia = {
    name: clip?.name ?? 'Clip',
    type: 'audio',
  }

  const { peaks } = usePeaks({ media })

  if (!clip) {
    return null
  }

  return (
    <Box className={className} ref={ref}>
      <Wave component={WaveContainer} peaks={peaks} position={0} />
    </Box>
  )
}

export default AudioClip
