import { styled } from '@mui/material'
import React from 'react'

// Note: The controls hover effect is handled with a more direct approach below

/** The wave container */
export const WaveContainer = styled('div')({
  display: 'flex',
  maxHeight: 150,
  flex: 1,
  marginBottom: 2,
})

export const PeaksPlaceholder = styled('div')(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(0, 1, 0, 1),
  position: 'absolute',
  top: theme.spacing(4),
  zIndex: 1,
  backgroundColor: theme.vars.palette.secondary.light,
  color: theme.vars.palette.secondary.contrastText,
}))

export const Timeline = styled('div')({})

export const RangeOverlay = styled('div')(({ theme }) => ({
  height: theme.spacing(0.5),
  width: '100%',
  display: 'flex',
}))

export const Fabs = styled('div')({
  flexDirection: 'column',
  alignItems: 'space-between',
})

export const RecorderFab = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(),
}))

interface FabProps {
  isVisible?: boolean
  component?: React.ElementType
}

export const Fab = styled('div', {
  shouldForwardProp: prop => prop !== 'isVisible' && prop !== 'component',
})<FabProps>(({ theme, isVisible }) => ({
  position: 'absolute',
  left: 'calc(50% - 28px)',
  right: theme.spacing(),
  zIndex: 100,
  display: isVisible ? 'flex' : 'none',
}))

export const Controls = styled('div')(({}) => ({
  width: '100%',
  position: 'absolute',
  display: 'none',
  alignItems: 'center',
  top: 0,
  right: 0,
  zIndex: 1300,
  opacity: 0.9,
  // [`${WaveformContainer}:hover &`]: {
  //   display: 'flex',
  // },
}))
