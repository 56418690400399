import type { BoxProps } from '@mui/material'
import { Box, CircularProgress, Divider, LinearProgress, Fab as MuiFab, Paper, Typography, Zoom } from '@mui/material'
import type { VirtualElement } from '@popperjs/core'
import { Play, Stop } from '@tunasong/icons'
import type { AudioPosition, ClipRegion } from '@tunasong/models'
import { EventFilters } from '@tunasong/models'
import type { AudioEvent, Entity, Media, Persisted } from '@tunasong/schemas'
import { isAudio, isPersisted } from '@tunasong/schemas'
import { Popup, useContainerSize, useDelayedInvoke, useDimensions, useHotkey } from '@tunasong/ui-lib'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import { useAudioTransportControl, usePeaks, useTimelineInteraction, useTransport } from '../hooks/index.js'
import { trackMouse } from '../lib/track-mouse.js'
import { useGlobalPlayer } from '../player/global-player.hook.js'
import { Timeline } from '../timeline/index.js'
import { CommentStream } from './comment-stream.js'
import { Event } from './event.js'
import WaveCanvas from './wave-canvas.js'
import { WaveContainer } from './waveform.styles.js'

export interface WaveformProps extends BoxProps {
  className?: string
  // mixer: Mixer | null
  // mediaEl?: HTMLMediaElement | null
  parent?: Entity

  /** Use the Waveform from this media */
  media?: Persisted<Media>

  /** Use the  Waveform from this URL */
  url?: string

  /** Automatically stop when another clip is played. @default false */
  autoStop?: boolean

  /** The active event to display at the current position */
  activeEvent?: AudioEvent

  /** ShowEvents */
  showEvents?: boolean | 'hover'

  /** show comments stream. @default false */
  showCommentStream?: boolean

  /** All the events. Set to null if there are no events */
  events?: AudioEvent[]

  regions?: ClipRegion[]

  /** If timeline is 'time', events are omitted from timeline */
  timeline?: 'time' | boolean

  badge?: string | null

  /** Render the peaks from the source audio if pre-rendered peaks does not exist */
  renderPeaks?: boolean

  /** Keyboard controls - e.g., play on Space */
  keyboardControls?: boolean

  // onPlay?(): void
  // onPause?(): void
  // recorder?: Recorder
  // /** Show region controls */
  // showRegion?: boolean
  // /** Show rate controls */
  // showRateControls?: boolean
  // /** Show the timeline */

  // onUpdateRegions?(regions: ClipRegion[])
  // onPlaying?(pos: AudioPosition)
  // onRecording?(media: AudioMedia, recording: Blob)
}

export const Waveform: FC<WaveformProps> = props => {
  const {
    className,
    media,
    url,
    activeEvent,
    events,
    showEvents = true,
    showCommentStream = false,
    timeline = true,
    keyboardControls = false,
    badge = media?.name,
    renderPeaks = false,
    ...restProps
  } = props

  /** Ref to the top-level container */
  const ref = useRef<HTMLDivElement | null>(null)

  const showHoverEvents = showEvents === true || showEvents === 'hover'

  const { isSmallOrSmaller } = useDimensions()

  invariant(!(media && url), 'Cannot specify both media and url')

  const hoverEvents = useRef<AudioEvent[]>([])

  /** Wire up the audio element to the player  */
  const audio = isAudio(media) ? media : undefined

  const { peaks, loading: loadingPeaks } = usePeaks({ media: audio, url, renderPeaks })

  const {
    controls,
    playlist,
    isPlaying: isPlayingGlobal,
    duration: playerDuration,
    position,
    playerRef,
  } = useGlobalPlayer()

  const baseDuration = audio?.features?.base?.duration ?? 0
  const duration = isFinite(playerDuration) ? playerDuration : baseDuration

  // Global playlist is our playlist.
  const isOurPlaylist = Boolean(playlist.active?.media?.id === media?.id)
  const isPlaying = Boolean(isPlayingGlobal && isOurPlaylist)

  const handleToggle = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    /**
     * To allow embed in containers that react to clicks, e.g., cards
     * But the audio engine needs to be initialized first.
     */
    ev.preventDefault()
    ev.stopPropagation()
    // Ensure that the playlist is initialized before playing

    if (!media) {
      return
    }
    if (!isPlaying) {
      playlist.playNow(media)
    } else {
      controls.pause()
    }
  }

  /** AudioTransport control */
  useAudioTransportControl(isOurPlaylist ? playerRef : null)

  const { transport } = useTransport({ events })
  const [pos, setPos] = useState(0)
  const mediaUrl = media?.storageUrls?.url
  const loading = loadingPeaks

  /** Calculate pixelsPerSecond on every render. Otherwise we need to  */

  const containerSize = useContainerSize(ref.current)
  const width = containerSize?.width ?? 0
  const pixelsPerSecond = useMemo(() => {
    const val = width / duration
    return Number.isNaN(val) ? 1 : val
  }, [duration, width])

  const [popupEl, setPopupEl] = useState<VirtualElement | null>(null)

  const handleSeek = useCallback(
    (pos: AudioPosition) => {
      controls.seek(pos.seconds)
      setPos(pos.seconds / duration)
    },
    [duration, controls]
  )
  const {
    position: audioPosition,
    pagePosition,
    timelineRef: containerRef,
  } = useTimelineInteraction<HTMLCanvasElement>({
    duration: playerRef?.duration,
    onSeek: handleSeek,
  })

  useHotkey({ hotkey: 'Space', handler: controls.togglePlay, disabled: !keyboardControls, el: ref.current })

  const debounceEl = useDelayedInvoke(50)

  /** If there's an active event, show it in the popup */
  useEffect(() => {
    setPopupEl(null)
    debounceEl(() => {
      if (!activeEvent || !showHoverEvents) {
        return
      }
      const pageX = containerRef.current?.getBoundingClientRect()?.left ?? 0
      const pageY = containerRef.current?.getBoundingClientRect()?.top ?? 0

      const x = activeEvent.start * pixelsPerSecond + pageX
      const y = pageY + 8

      setPopupEl({
        getBoundingClientRect: () => ({
          top: y,
          right: x,
          bottom: y,
          left: x,
          width: 0,
          height: 0,
          x,
          y,
          toJSON: () => '',
        }),
      })
      hoverEvents.current = [activeEvent]
    })
  }, [activeEvent, containerRef, debounceEl, pixelsPerSecond, showHoverEvents])

  useEffect(() => {
    setPopupEl(null)
    debounceEl(() => {
      if (!pagePosition || !showHoverEvents) {
        return
      }
      setPopupEl(trackMouse(pagePosition))
      hoverEvents.current = (events ?? []).filter(EventFilters.contains(pagePosition.seconds))
    })
  }, [debounceEl, events, pagePosition, showHoverEvents])

  /**
   * Refresh position every 100ms when running.
   * @note useAnimationFrame was used previously but was too expensive
   */

  useEffect(() => {
    if (!isPlaying) {
      return
    }
    const id = setInterval(() => {
      const pos = duration ? transport.seconds / duration : 0
      setPos(pos)
    }, 100)
    return () => {
      clearInterval(id)
    }
  }, [duration, isPlaying, media?.name, transport])

  const fabSize = isSmallOrSmaller ? 'small' : 'medium'

  const contentUrl = url ?? mediaUrl
  const hasContent = Boolean(contentUrl)

  return (
    <Box sx={{ flexDirection: 'column' }} ref={ref} className={className} {...restProps}>
      {timeline ? (
        <Timeline
          duration={duration}
          events={showEvents === true && timeline !== 'time' ? events : undefined}
          pixelsPerSecond={pixelsPerSecond}
        />
      ) : null}
      {/* <ActiveRegion region={activeRegion} duration={duration} /> */}
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {hasContent ? (
          <WaveContainer>
            <WaveCanvas ref={containerRef} peaks={peaks} position={pos} badge={badge} />
          </WaveContainer>
        ) : (
          <LinearProgress sx={{ width: '100%' }} />
        )}
        <Zoom in={true} timeout={200}>
          {loading ? (
            <MuiFab
              color="secondary"
              size={fabSize}
              sx={{ position: 'absolute', left: 'calc(50% - 28px)', zIndex: 100 }}
            >
              <CircularProgress />
            </MuiFab>
          ) : (
            <MuiFab
              color="secondary"
              disabled={loadingPeaks}
              onClick={handleToggle}
              size={fabSize}
              sx={{ position: 'absolute', left: 'calc(50% - 28px)', zIndex: 100 }}
            >
              {!isPlaying ? <Play /> : <Stop />}
            </MuiFab>
          )}
        </Zoom>
      </Box>
      {isPersisted(media) && showCommentStream ? (
        <CommentStream
          audioPosition={position}
          playing={isPlaying}
          parent={media}
          height={24}
          pixelsPerSecond={pixelsPerSecond}
          duration={duration}
        />
      ) : null}
      {/* {showRegion ? <WaveRegion region={activeRegion} onDelete={handleRegionDelete} onChange={handleRegionEdit} /> : null} */}
      {/* Popup */}
      <Popup open={Boolean(popupEl)} anchorEl={popupEl} placement="right-end">
        <Paper
          sx={{
            px: 1,
            paddingBottom: 1,
            borderRadius: 0,
            borderLeft: theme => `2px solid ${theme.vars.palette.secondary.main}`,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="caption" display={'flex'}>
            {audioPosition?.label}
          </Typography>
          {hoverEvents.current.map((event, idx) => (
            <React.Fragment key={idx}>
              {idx > 0 ? <Divider /> : null}
              <Event event={event} />
            </React.Fragment>
          ))}
        </Paper>
      </Popup>
    </Box>
  )
}

export default Waveform
