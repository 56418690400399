import { useTheme } from '@mui/material'
import { isChordEvent, isMarkerEvent } from '@tunasong/schemas'
import { getColorCSS } from '@tunasong/ui-lib'
import type { MouseEvent } from 'react'
import { useCallback, useState } from 'react'
import type { RendererPlugin } from '../renderer.js'
import { MarkerText, Marker as StyledMarker } from './styles.js'

const Marker: RendererPlugin = ({ laneHeight, event, pixelsPerSecond, onEventClick }) => {
  const theme = useTheme()
  const [textRef, setTextRef] = useState<SVGTextElement | null>(null)

  const handleEventClick = useCallback(
    (ev: MouseEvent) => {
      if (!onEventClick) {
        return
      }
      onEventClick(ev, event)
    },
    [event, onEventClick]
  )

  if (!(isMarkerEvent(event) || isChordEvent(event))) {
    return null
  }

  const { color } = event
  /** @note we cannot use theme.vars (CSS vars) here because we do math */
  const fill = color ? getColorCSS(color)[200] : theme.palette.secondary.main
  const textColor = theme.palette.getContrastText(fill)

  const labelPadding = 4

  const label = event.name
  const x = event.start * pixelsPerSecond
  const y = laneHeight * 3

  if (Number.isNaN(x) || Number.isNaN(y)) {
    return null
  }

  const rectY = laneHeight * 2
  const textX = x + labelPadding
  const textY = y - labelPadding

  const bbox = textRef?.getBBox() ?? { width: 0, height: 0 }
  const rectWidth = bbox.width + 2 * labelPadding
  const rectHeight = bbox.height + 2 * labelPadding

  /** We want to have a label on  */
  return (
    <StyledMarker onClick={handleEventClick}>
      <title>{label}</title>
      <rect fill={fill} x={x} y={rectY} width={rectWidth} height={rectHeight} />
      <MarkerText ref={setTextRef} x={textX} y={textY} fill={textColor}>
        {label}
      </MarkerText>
    </StyledMarker>
  )
}

export default Marker
