import { IconButton } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Offline, Pause, Play } from '@tunasong/icons'
import { isOnline } from '@tunasong/models'
import { EntityLink } from '@tunasong/ui-lib'
import { useMixer } from '../hooks/mixer.hook.js'
import Meter from '../meter.js'
import { MidiIndicator } from '../midi/midi-indicator.js'
import { useGlobalPlayer } from './global-player.hook.js'

/** Dynamic Global Player button that will provide controls if no other players are connected to the global player */
export const GlobalPlayerButton = ({ showStatus = true }: { showStatus?: boolean }) => {
  const { isPlaying, controls, playlist } = useGlobalPlayer()
  const mainBus = useMixer().getBus('monitor')

  const { entity: sourceEntity } = graphHooks.useEntity(playlist.active?.sourceEntityId ?? playlist.active?.media?.id)

  return (
    <>
      {/* If this component is the only one using the global player, and there's a playlist, show the play/pause button */}
      {showStatus ? (
        <>
          {isOnline() ? null : <Offline titleAccess="Offline" color="secondary" />}
          {/* the meter uses an useAnimationFrameLoop hook and is costly to run without any playback */}
          {isPlaying ? <Meter color="secondary" controller={mainBus} orientation="vertical" /> : null}
          <MidiIndicator />
        </>
      ) : null}
      {sourceEntity ? <EntityLink entity={sourceEntity} showName={false} /> : null}
      {playlist.tracks.length > 0 ? (
        <IconButton
          sx={{
            color: 'inherit',
            '@keyframes color-pulse': theme => ({
              '0%': {
                color: theme.vars.palette.secondary.main,
              },
              '50%': {
                color: theme.vars.palette.primary.main,
              },
              '100%': {
                color: theme.vars.palette.secondary.main,
              },
            }),
            animation: isPlaying ? 'color-pulse 2s ease infinite' : 'none',
          }}
          onClick={controls.togglePlay}
        >
          {isPlaying ? <Pause /> : <Play />}
        </IconButton>
      ) : null}
    </>
  )
}
