import VolumeDown from '@mui/icons-material/VolumeDown'
import VolumeMute from '@mui/icons-material/VolumeOff'
import VolumeUp from '@mui/icons-material/VolumeUp'
import { Box, Grid, Slider, Typography } from '@mui/material'
import { Audio } from '@tunasong/icons'
import { isMobileDevice } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { AudioController } from './engine/audio-controller.js'
import { Meter } from './meter.js'

export interface VolumeProps {
  title?: string
  controller: AudioController
  onChange?(value: number): void
  onClose?(): void
}

export const Volume: FC<VolumeProps> = props => {
  const { controller, onChange, onClose, title = 'Volume' } = props

  const handleVolume = (ev: unknown, value: number | number[]) => {
    if (typeof value !== 'number') {
      return
    }

    controller.gain = value
    if (onChange) {
      onChange(value)
    }
  }

  const handleMute = () => {
    controller.muted = !controller.muted
  }

  const handleMonitor = (val: boolean) => () => {
    // eslint-disable-next-line react-compiler/react-compiler
    controller.monitor = val
    if (!isMobileDevice()) {
      controller.enableDSPFeatures(val)
    }
  }

  const handleMax = (ev: unknown) => handleVolume(ev, 1)

  return (
    <Box
      sx={{
        margin: theme => theme.spacing(),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom variant="caption">
        {title}
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 1 }}>
          {controller.type === 'mic' ? (
            controller.monitor ? (
              <Audio color="warning" onClick={handleMonitor(false)} />
            ) : (
              <Audio onClick={handleMonitor(true)} />
            )
          ) : null}
        </Grid>

        <Grid size={{ xs: 1 }}>
          {controller.muted ? <VolumeMute color="error" onClick={handleMute} /> : <VolumeDown onClick={handleMute} />}
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          size={{ xs: 9 }}
        >
          <Slider
            sx={{ minWidth: 100 }}
            disabled={controller.muted}
            value={controller.gain}
            step={0.01}
            min={0.0}
            max={1.0}
            onChange={handleVolume}
            onDragEnd={onClose}
          />
          <Meter controller={controller} at="pre" />
          {controller.trackType === 'bus' ? <Meter controller={controller} at="post" color="primary" /> : null}
        </Grid>
        <Grid size={{ xs: 1 }}>
          <VolumeUp onClick={handleMax} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Volume
