import { IconButton, Typography } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { Next, Play, Prev, Stop } from '@tunasong/icons'
import type { AudioPosition } from '@tunasong/models'
import type { Audio, Persisted, Video } from '@tunasong/schemas'
import { HBox, VBox } from '@tunasong/ui-lib'
import { useState } from 'react'
import type { FC, MouseEvent } from 'react'
import { useTimelineInteraction } from '../hooks/index.js'
import { usePlayerControl } from './player-control.hook.js'
import { PlayerProgress } from '../progress.js'

export interface PlayerButtonProps extends BoxProps {
  audio: Persisted<Video | Audio> | Persisted<Video | Audio>[]
  /** Hide progress and name */
  compact?: boolean
  /** Show the name label */
  showName?: boolean
  /** always show or hide progress. @default is to show when playing */
  showProgress?: boolean
}

export const PlayerButton: FC<PlayerButtonProps> = props => {
  const {
    audio,
    compact = false,
    showProgress: providedShowProgress,
    showName = true,

    ...restProps
  } = props

  const [ref, setRef] = useState<HTMLAudioElement | null>(null)

  const audioList = Array.isArray(audio) ? audio : [audio]

  const [currentAudio, setCurrentAudio] = useState<Persisted<Audio | Video>>(audioList[0])
  const handleSelect = (idx: number) => setCurrentAudio(audioList[idx])

  const { audioProps, next, prev, togglePlay, seek } = usePlayerControl({
    name: currentAudio?.name,
    el: ref,
    media: audioList,
    active: currentAudio,
    onSelect: handleSelect,
  })

  const handleClick = (ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    togglePlay()
  }

  const src = currentAudio?.storageUrls?.url

  const { timelineRef } = useTimelineInteraction({
    duration: ref?.duration,
    onSeek: (pos: AudioPosition) => seek(pos.seconds),
  })

  const size = compact ? 'small' : 'medium'
  const showControls = audioList.length > 1
  const showProgress = providedShowProgress !== undefined ? providedShowProgress : audio && !ref?.paused && !compact

  return (
    <VBox {...restProps}>
      <HBox sx={{ alignItems: 'center', containerName: 'PlayerButtonHBox' }}>
        <audio {...audioProps} ref={setRef} controls={false} src={src} crossOrigin="anonymous" />
        {showControls ? (
          <IconButton sx={{ padding: 0 }} disabled={!prev} onClick={prev} color="primary" size={size}>
            <Prev />
          </IconButton>
        ) : null}
        <IconButton
          sx={{ padding: 0 }}
          disabled={!audio}
          onClick={handleClick}
          color="primary"
          size={size}
          title={currentAudio?.name ?? 'Not playing'}
        >
          {ref?.paused ? <Play /> : <Stop />}
        </IconButton>
        {showControls ? (
          <IconButton disabled={!next} onClick={next} color="primary" size={size}>
            <Next />
          </IconButton>
        ) : null}
        {showProgress ? <PlayerProgress sx={{ ml: 2 }} ref={timelineRef} el={ref} /> : null}
      </HBox>
      {!compact && showName ? (
        <Typography variant="caption" color="primary">
          {currentAudio?.name}
        </Typography>
      ) : null}
    </VBox>
  )
}

export default PlayerButton
