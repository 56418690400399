import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { Media, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import { useGlobalPlayer } from './global-player.hook.js'
import { PlayerControls } from './player-controls.js'

export interface CompactPlayerProps extends BoxProps {
  name?: string
  entities: Persisted<Media>[]
  /** Capture keyboard events to control the player */
  keyboardControl?: boolean
  sourceEntityId?: string
}

export const CompactPlayer: FC<CompactPlayerProps> = props => {
  const { entities, name, keyboardControl = false, sourceEntityId, ...restProps } = props

  const { controls, playlist } = useGlobalPlayer({ initialPlaylist: entities, sourceEntityId })

  return (
    <Box {...restProps}>
      <PlayerControls
        sx={{ width: '100%' }}
        name={name ?? playlist.active?.media?.name}
        keyboardControl={keyboardControl}
        onSeek={controls.seek}
      />
    </Box>
  )
}

export default CompactPlayer
