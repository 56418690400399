import { useDragMove } from '@tunasong/ui-lib'
import { styled } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import type { FC, MouseEvent } from 'react'
import plugins from './plugins.js'
import type { TimelineEvent } from './timeline-event.js'
import { getClickPositionSeconds } from './util.js'

export interface TimelineProps {
  className?: string
  duration: number
  pixelsPerSecond: number
  height?: number
  events?: TimelineEvent[]
  /** Click on the timeline at position in seconds */
  onDragMove?(ev: globalThis.MouseEvent): void
  onClick?(ev: MouseEvent, seconds: number, lane?: number): void
  onEventClick?(ev: MouseEvent, event: TimelineEvent): void
  onUpdateEvent?(event: TimelineEvent): void
}

interface TimelineContainerProps {
  containerHeight: number;
}

const TimelineContainer = styled('div')<TimelineContainerProps>(({ containerHeight }) => ({
  width: '100%',
  height: containerHeight,
}))

const TimelineSvg = styled('svg')<TimelineContainerProps>(({ containerHeight }) => ({
  width: '100%',
  height: containerHeight,
}))

export const Timeline: FC<TimelineProps> = props => {
  const {
    className,
    duration,
    pixelsPerSecond,
    height = 36,
    events,
    onClick,
    onDragMove,
    onEventClick,
    onUpdateEvent,
  } = props

  // Height is passed directly to styled components

  const [canvas, setCanvas] = useState<SVGElement | null>(null)
  const [rootEl, setRootEl] = useState<HTMLDivElement | null>(null)

  const lanes = 3
  const laneHeight = Math.round(height / lanes)

  const timeInterval = useMemo(() => {
    /** Max 1 interval per 50 pixels, rounding to 10 second intervals */
    const pixelsPerInterval = 50
    const roundTo = 10
    const interval = (duration * pixelsPerSecond) / pixelsPerInterval
    const timeInterval = Math.floor(duration / interval)
    const val = Math.max(Math.ceil(timeInterval / roundTo) * roundTo, 1)
    return Number.isSafeInteger(val) ? val : 10
  }, [duration, pixelsPerSecond])

  const getLane = useCallback(
    (ev: MouseEvent) => {
      const top = canvas?.getBoundingClientRect().top ?? 0
      const y = ev.clientY - top
      const lane = Math.floor(y / laneHeight) + 1.0
      return lane
    },
    [canvas, laneHeight]
  )

  const timeline = useMemo(() => {
    if (!canvas || !Number.isFinite(pixelsPerSecond) || pixelsPerSecond <= 0) {
      return []
    }
    /**
     * Layout is as follows:
     *
     * 1/4 is used for time labels
     * 1/4 is used for notches
     * 1/2 is used for labels (if labels are specified)
     */

    const positionEvents: TimelineEvent[] = []
    let seconds = 0
    for (let index = 0; index < duration / timeInterval; index++) {
      positionEvents.push({ type: 'position', start: seconds, name: 'Notch' })
      seconds += timeInterval
    }

    const allEvents = [...positionEvents, ...(events ?? [])]

    /** Process the positions with the plugins */
    const elements = allEvents
      .map((event, eIdx) =>
        plugins.map((Plugin, idx) => (
          <Plugin
            key={`${eIdx}-${idx}`}
            event={event}
            laneHeight={laneHeight}
            pixelsPerSecond={pixelsPerSecond}
            onEventClick={onEventClick}
            onUpdateEvent={onUpdateEvent}
          />
        ))
      )
      .flat()
    return elements
  }, [canvas, duration, events, laneHeight, onEventClick, onUpdateEvent, pixelsPerSecond, timeInterval])

  const handleClick = (ev: MouseEvent) => {
    if (!onClick) {
      return
    }
    const seconds = getClickPositionSeconds(ev, duration)
    const lane = getLane(ev)
    onClick(ev, seconds, lane)
  }

  /** Only allow drag events from the third lane  */
  const handleDragStart = (ev: globalThis.MouseEvent) => {
    const lane = getLane(ev as never)
    return lane === 3
  }

  useDragMove({ el: rootEl, onStart: handleDragStart, onMove: onDragMove })

  return (
    <TimelineContainer className={className} containerHeight={height} ref={setRootEl} draggable={false}>
      {/* If we want a fixed coordinate system we can use the viewbox viewBox="0 0 100 100"  */}
      <TimelineSvg 
        ref={setCanvas} 
        containerHeight={height}
        preserveAspectRatio="none" 
        onClick={handleClick}
      >
        {timeline}
      </TimelineSvg>
    </TimelineContainer>
  )
}
