/** Midi indicator */

import { Midi } from '@tunasong/icons'
import { getColorCSS } from '@tunasong/ui-lib'
import { useCallback, useEffect, useState } from 'react'
import { useMidi } from './midi.hook.js'
import { Box } from '@mui/material'

export const MidiIndicator = () => {
  const [activityCounter, setActivityCounter] = useState(0)
  const onMidiEvent = useCallback(() => {
    setActivityCounter(activityCounter => activityCounter + 1)
  }, [])
  // Count down activity
  useEffect(() => {
    if (activityCounter === 0) {
      return
    }
    const timeout = setInterval(() => {
      setActivityCounter(activityCounter => activityCounter - 1)
    }, 100)
    return () => clearInterval(timeout)
  })
  const { inputs, outputs } = useMidi({ onMidiEvent })

  const hasInputs = inputs?.length ?? 0
  const hasOutputs = outputs?.length ?? 0
  const isConnected = hasInputs || hasOutputs

  // Using the modern styled component approach instead of classes
  return isConnected ? (
    activityCounter > 0 ? (
      <Box sx={{ color: getColorCSS('red') }}>
        <Midi />
      </Box>
    ) : (
      <Midi />
    )
  ) : null
}
