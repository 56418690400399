import { LinearProgress } from '@mui/material'
import { useRedraw } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useEffect } from 'react'
import { AudioController } from './engine/audio-controller.js'
import { getDBPercentage } from './lib/db.js'

export interface AudioActivityProps {
  controller: AudioController
}

export const AudioActivity: FC<AudioActivityProps> = props => {
  const { controller } = props
  const refresh = useRedraw()

  /** Re-render the audio meters every 50ms */
  useEffect(() => {
    const interval = setInterval(() => refresh(), 50)
    return () => {
      clearInterval(interval)
    }
  }, [refresh])

  const peak = controller?.getDB().peak ?? 0
  const percentage = getDBPercentage({ value: peak, reference: 0 })
  const color = 'secondary'

  return (
    <LinearProgress
      sx={{
        minWidth: 50,
      }}
      variant="determinate"
      color={color}
      value={percentage}
    />
  )
}

export default AudioActivity
