import { Popper, Typography, styled } from '@mui/material'
import { formatNumber } from '@tunasong/models'
import { useDragMove } from '@tunasong/ui-lib'
import { useCallback, useState } from 'react'
import type { FC } from 'react'
import { getPercentageDB } from '../../lib/index.js'

// Interface is defined at the top of the file

const VolumeContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
})

const VolumeBar = styled('div')(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.vars.palette.action.hover,
  cursor: 'pointer',
  marginRight: theme.spacing(0.5),
  height: theme.spacing(0.5),
}))

interface VolumeLevelProps {
  volumeLevel: number
}

const VolumeLevel = styled('div')<VolumeLevelProps>(({ theme, volumeLevel }) => ({
  width: `${Math.min(Math.min(volumeLevel * 100, 100), 100)}%`,
  backgroundColor: theme.vars.palette.primary.light,
  height: theme.spacing(0.5),
}))

export interface ChannelVolumeProps {
  className?: string
  volume: number
  onChangeVolume(volume: number): void
}

export const ChannelVolume: FC<ChannelVolumeProps> = props => {
  const { className, volume, onChangeVolume } = props

  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  /** Dragging the volume bar? */

  const handleVolume = useCallback(
    (ev: MouseEvent) => {
      if (!ref) {
        return
      }
      ev.preventDefault()
      const box = ref.getBoundingClientRect()
      const percentage = Math.max(Math.min(((ev.clientX - box.left) / box.width) * 100, 100), 0)
      onChangeVolume(percentage / 100)
    },
    [onChangeVolume, ref]
  )
  const [showDB, setShowDB] = useState(false)
  const handleStart = useCallback(
    (ev: MouseEvent) => {
      handleVolume(ev)
      setShowDB(true)
    },
    [handleVolume]
  )

  const handleStop = useCallback(
    (ev: MouseEvent) => {
      handleVolume(ev)
      setShowDB(false)
    },
    [handleVolume]
  )

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  useDragMove({
    el: ref,
    onMove: handleVolume,
    onStart: handleStart,
    onStop: handleStop,
  })

  const db = `${formatNumber(getPercentageDB({ value: volume * 100 }), { digits: 1 })}dB`

  return (
    <VolumeContainer className={className} title={db} ref={setAnchorEl}>
      <VolumeBar ref={setRef}>
        <VolumeLevel volumeLevel={volume} />
      </VolumeBar>
      <Popper open={showDB} anchorEl={anchorEl} placement="top">
        <Typography variant="caption">{db}</Typography>
      </Popper>
    </VolumeContainer>
  )
}

export default ChannelVolume
