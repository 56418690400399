import { logger, type AudioPosition } from '@tunasong/models'
import { useDelayedInvoke } from '@tunasong/ui-lib'
import React, { useCallback, useEffect, useState } from 'react'

export interface PlayerProps {
  /** Name of the player to be shown in the Mixer */
  el: HTMLMediaElement | null
  name?: string
  volume?: number
  /** Automatically stop when another player is started */
  autoStop?: boolean

  onPlay?(): void
  onPause?(): void
}

export interface MixedPlayer {
  audioProps: Partial<React.DetailedHTMLProps<React.AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>>
  playing: boolean
  duration: number
  position: number
  play(): void
  pause(): void
  seek(seconds: number): void
  togglePlay(): void
}

/**
 * The AudioPlayer will play a single audio file. Events will he scheduled on the Timeline.
 * Only one AudioPlayer works at the same time because the Timeline is a singleton connected to
 * the AudioContext.
 */

export const useAudioPlayer = ({ el, onPause, onPlay }: PlayerProps): MixedPlayer => {
  const playing = Boolean(el && el.paused !== true)

  const play = () => {
    if (!el) {
      return
    }

    if (onPlay) {
      onPlay()
    }

    el.pause()

    return el.play().catch(err => {
      logger.error('Error playing', err)
      throw err
    })
  }

  const seek = (seconds: number | AudioPosition) => {
    if (!el) {
      return
    }
    const secs = typeof seconds === 'number' ? seconds : seconds.seconds
    /** We will sync the transport to this when seek is done */
    // eslint-disable-next-line react-compiler/react-compiler
    el.currentTime = secs
  }

  const pause = useCallback(() => {
    if (!el) {
      return
    }

    el.pause()
    if (onPause) {
      onPause()
    }
  }, [el, onPause])

  /** Update position. We debounce to avoid a lot of updates */
  const [position, setPosition] = useState(0)
  const debouncePosition = useDelayedInvoke(100)
  useEffect(() => {
    if (!el) {
      return
    }
    const handleTimeUpdate = () => debouncePosition(() => setPosition(el.currentTime))
    el.addEventListener('timeupdate', handleTimeUpdate)
    return () => {
      el.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [debouncePosition, el, playing])

  const togglePlay = async () => {
    if (!el) {
      return
    }
    if (el.paused) {
      await play()
    } else {
      pause()
    }
  }
  const [duration, setDuration] = useState(el?.duration ?? 0)

  /** Update playing status */
  useEffect(() => {
    if (!el) {
      return
    }
    // const handlePause = () => dispatch(features.audio.actions.setPlayer({ isPlaying: false }))
    const handleDuration = (ev: Event) => {
      setDuration((ev.target as HTMLMediaElement).duration)
    }
    // this prevents us from auto-playing the next track
    // el.addEventListener('ended', handlePause)
    el.addEventListener('durationchange', handleDuration)

    return () => {
      // el.removeEventListener('ended', handlePause)
      el.removeEventListener('durationchange', handleDuration)
    }
  }, [el])

  return {
    playing,
    play,
    seek,
    pause,
    togglePlay,
    duration,
    position,
    audioProps: {
      crossOrigin: 'anonymous',
    },
  }
}
