import { Box } from '@mui/material'
import React from 'react'

export interface MarkerProps {
  className?: string
}

export const Marker = React.forwardRef<HTMLDivElement, MarkerProps>((props, ref) => {
  const { className } = props

  /** Update the marker */
  return (
    <Box
      sx={{
        position: 'absolute',
        width: 2,
        backgroundColor: theme => theme.vars.palette.primary.light,
        opacity: 0.3,
        zIndex: 1,
        height: '100%',
      }}
      className={className}
      ref={ref}
    />
  )
})

export default Marker
