/** A global, headless player */
import { logger, shortUuid } from '@tunasong/models'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { IAudioContext, IMediaElementAudioSourceNode } from 'standardized-audio-context'
import { useMixer } from '../hooks/mixer.hook.js'
import { useGlobalPlayerState } from './global-player-state.hook.js'

export const GlobalAudioPlayer = () => {
  const { setPlayerRef, currentSrc } = useGlobalPlayerState()

  const id = useMemo(() => shortUuid(), [])
  const mixer = useMixer()

  /** Register channel in the mixer */
  const [localRef, setLocalRef] = useState<HTMLAudioElement | null>(null)
  const elSource = useRef<IMediaElementAudioSourceNode<IAudioContext> | null>(null)

  const channelId = useRef<string>(null)

  const removeChannel = useCallback(() => {
    if (!channelId.current) {
      return
    }

    // Disconnect the MediaElementSource from the audio context
    if (elSource.current) {
      try {
        elSource.current.disconnect()
        elSource.current = null
      } catch (e) {
        // Handle possible errors if already disconnected
        logger.debug('Error disconnecting source', e)
      }
    }

    /**
     * We don't disconnect MediaElementSource here, we reuse it to avoid:
     * HTMLMediaElement already connected previously to a different MediaElementSourceNode
     */
    mixer.removeChannel(channelId.current)
    channelId.current = null
  }, [mixer])

  const setupChannel = useCallback(() => {
    if (!(localRef && mixer)) {
      return
    }

    const id = `audioplayer-${shortUuid()}`

    logger.debug('Setting up channel', {
      id,
      channelId: channelId.current,
      elSource,
      localRef,
    })
    // Remove previous channel
    removeChannel()

    channelId.current = id

    /** If we use the same MediaElement we cannot re-create the media source */

    const source = elSource.current ? elSource.current : mixer.context.createMediaElementSource(localRef)

    elSource.current = source

    mixer.addChannel(
      {
        id,
        name: 'Global Audio Player',
        type: 'general',
        // gain: volume,
        inputNode: source,
      },
      'media'
    )
    logger.debug('Channel is set up', {
      id,
      channelId: channelId.current,
      elSource,
      localRef,
    })
    // Update the global player reference
    setPlayerRef(localRef)
  }, [localRef, mixer, removeChannel, setPlayerRef])

  // Setup channel
  useEffect(() => {
    setupChannel()
    return () => {
      removeChannel()
    }
  }, [removeChannel, setupChannel])

  return <audio key={id} id={id} crossOrigin="anonymous" ref={setLocalRef} src={currentSrc} controls={false} />
}
