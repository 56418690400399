import type { ButtonProps } from '@mui/material'
import { ButtonBase } from '@mui/material'
import type { FC } from 'react'

export interface ChannelButtonProps extends ButtonProps {
  className?: string
  selected?: boolean
}

export const ChannelButton: FC<ChannelButtonProps> = props => {
  const { className, selected = false, ...restProps } = props
  return (
    <ButtonBase
      sx={theme => ({
        backgroundColor: selected ? theme.vars.palette.action.selected : theme.vars.palette.action.hover,
        color: selected ? theme.vars.palette.secondary.light : 'inherit',
        padding: 2,
        margin: 2,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 20,
        height: 20,
        '& >svg': {
          width: 16,
          height: 16,
        },
      })}
      className={className}
      {...restProps}
    />
  )
}

export default ChannelButton
