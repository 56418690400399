import type { AudioPosition } from '@tunasong/models'
import { useEffect, useRef, useState } from 'react'
import { formatTime } from '../lib/format-time.js'

/** Interaction with a rendered wave */
interface TimelineInteraction {
  /** Duration of the timeline in seconds */
  duration?: number

  onSeek?(pos: AudioPosition): void
}

/** Track interaction with a container representing audio or timeline data */
export const useTimelineInteraction = <T extends HTMLElement = HTMLDivElement>(params: TimelineInteraction) => {
  const { duration = 0.0, onSeek } = params

  const timelineRef = useRef<T | null>(null)

  const [position, setPosition] = useState<AudioPosition | null>(null)
  const [active, setActive] = useState(false)
  const [pagePosition, setPagePosition] = useState<{ pageX: number; pageY: number; seconds: number } | null>(null)

  /** Mouse events */
  useEffect(() => {
    const container = timelineRef.current
    if (!container) {
      return
    }

    /** Calculate the position in the audio + region */
    const audioPos = (e: MouseEvent): AudioPosition => {
      if (!Number.isFinite(duration)) {
        return { seconds: 0, label: 'Not playing' }
      }
      const bbox = container.getBoundingClientRect()
      const width = bbox.width
      const xpos = e.clientX - bbox.left

      /** @todo implement scrolltime */
      const scrollTime = 0

      const seconds = Math.max(0, (xpos / width) * duration) + scrollTime
      const label = formatTime(seconds)
      return {
        seconds,
        label,
      }
    }

    const handleEv = (ev: MouseEvent) => {
      ev.preventDefault()
      const pos = audioPos(ev)
      setPagePosition({ pageX: ev.pageX, pageY: ev.pageY, seconds: pos.seconds })
    }

    const mouseEnter = (ev: MouseEvent) => {
      handleEv(ev)
      setActive(true)
    }

    const mouseLeave = (ev: MouseEvent) => {
      handleEv(ev)
      setPosition(null)
      setPagePosition(null)
      setActive(false)
    }

    const mouseMove = (ev: MouseEvent) => {
      handleEv(ev)
      const pos = audioPos(ev)
      setPosition(pos)
    }

    const click = (ev: MouseEvent) => {
      if (onSeek) {
        ev.preventDefault()
        onSeek(audioPos(ev))
      }
    }

    container.addEventListener('click', click)
    container.addEventListener('mouseenter', mouseEnter)
    container.addEventListener('mouseleave', mouseLeave)
    container.addEventListener('mousemove', mouseMove)
    return () => {
      container.removeEventListener('click', click)
      container.removeEventListener('mouseenter', mouseEnter)
      container.removeEventListener('mouseleave', mouseLeave)
      container.removeEventListener('mousemove', mouseMove)
    }
  }, [active, duration, onSeek])

  return {
    active,
    position,
    pagePosition,
    timelineRef,
  }
}
