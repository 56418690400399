import { Box } from '@mui/material'
import type { Track } from '@tunasong/schemas'
import type { FC } from 'react'

export interface DetailsProps {
  className?: string
  track?: Track
}

export const Details: FC<DetailsProps> = props => {
  const { className, track } = props

  if (!track) {
    return null
  }
  return <Box className={className}>{track.name}</Box>
}

export default Details
