import type { ListItemButtonProps } from '@mui/material'
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Pause, Play, Song } from '@tunasong/icons'
import { dayjs } from '@tunasong/models'
import type { Entity, Media, Persisted } from '@tunasong/schemas'
import { EntityIcon, PulseProgress, useNavigateToEntity } from '@tunasong/ui-lib'
import type { FC, MouseEvent } from 'react'
import { useCallback } from 'react'

export interface PlayListItemProps extends ListItemButtonProps {
  entity: Persisted<Media>
  onPlayClicked?: (ev: MouseEvent) => void
  playing?: boolean
  progressLabel?: string
  showUpdated?: boolean
  showPageButton?: boolean
  showPlayButton?: boolean
}

export const PlayListItem: FC<PlayListItemProps> = props => {
  const {
    entity,
    progressLabel,
    playing = false,
    onPlayClicked,
    showPlayButton = false,
    showPageButton = true,
    showUpdated = false,
    ...restProps
  } = props

  const { backlinks } = graphHooks.useEntityBacklinks({ entityId: entity.id, relation: 'hasRecording' })

  const navigateToEntity = useNavigateToEntity()
  const handleNavigate = useCallback(
    (entityOrEntityId?: Persisted<Entity> | string) => (ev: MouseEvent) => {
      const entityId = typeof entityOrEntityId === 'string' ? entityOrEntityId : entityOrEntityId?.id
      if (entityId) {
        ev.preventDefault()
        ev.stopPropagation()
        const navigationMode = ev.shiftKey ? 'dialog' : 'page'
        navigateToEntity({ id: entityId }, { navigationMode })
      }
    },
    [navigateToEntity]
  )

  const source = backlinks.find(l => l.target === entity.id)?.source ?? entity.parentId

  const parent = graphHooks.useEntity(source)

  const secondary = showUpdated ? `Updated ${dayjs(entity.updatedAt).fromNow()}` : undefined

  const disabled = !parent

  return (
    <ListItemButton
      sx={{
        cursor: 'pointer',
      }}
      {...restProps}
    >
      <ListItemIcon>
        <EntityIcon entity={entity} size={'medium'} />
      </ListItemIcon>
      <ListItemText primary={entity.name} secondary={secondary} />
      <ListItemSecondaryAction>
        {showPlayButton && (
          <IconButton onClick={onPlayClicked} title="Toggle play">
            {playing ? (
              <>
                <Typography variant="caption">{progressLabel}</Typography>
                <PulseProgress size="small" />
                <Pause />
              </>
            ) : (
              <Play />
            )}
          </IconButton>
        )}

        {showPageButton && source ? (
          <IconButton disabled={disabled} onClick={handleNavigate(source)} title="Go to song/page">
            <Song />
          </IconButton>
        ) : null}
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}
