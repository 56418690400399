import { MenuList } from '@mui/material'
import type { Media, Persisted } from '@tunasong/schemas'
import type { FC, MouseEvent } from 'react'
import { useGlobalPlayer } from './global-player.hook.js'
import { PlayListItem } from './playlist-list-item.js'

export interface CompactPlaylistProps {
  clips: Persisted<Media>[]
  onSelect?: (entity: Persisted<Media>) => void
}

export const CompactPlaylist: FC<CompactPlaylistProps> = props => {
  const { clips, onSelect } = props

  const { playlist, isPlaying, progressLabel, controls } = useGlobalPlayer()

  const handleActivate = (entity: Persisted<Media>) => (ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()

    if (playlist.active?.media.id === entity.id) {
      controls.togglePlay()
      return
    }
    playlist.playNow(entity)
  }

  // Sort clips by updated date by ISO string
  const sortedClips = clips
    .sort((a, b) => {
      const aDate = new Date(a.updatedAt).getTime()
      const bDate = new Date(b.updatedAt).getTime()
      return bDate - aDate
    })
    // Filter out duplicates in sortedClips
    .filter((clip, index, self) => self.findIndex(t => t.id === clip.id) === index)

  const handleClick = (entity: Persisted<Media>) => (ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (onSelect) {
      onSelect(entity)
    }
  }

  return (
    <MenuList sx={{ width: '100%' }}>
      {sortedClips.map(entity => (
        <PlayListItem
          sx={{
            cursor: 'pointer',
          }}
          disableRipple
          key={entity.id}
          entity={entity}
          showUpdated
          showPlayButton
          showPageButton={false}
          playing={isPlaying && playlist.active?.media.id === entity.id}
          progressLabel={progressLabel}
          onPlayClicked={handleActivate(entity)}
          onClick={handleClick(entity)}
        />
      ))}
    </MenuList>
  )
}

export default CompactPlaylist
