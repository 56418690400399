import { Box, ButtonBase, type SxProps, type Theme } from '@mui/material'
import { Add, Delete, Pool, Preview } from '@tunasong/icons'
import type { Track } from '@tunasong/schemas'
import type { FC } from 'react'

const actionButtonSx: SxProps<Theme> = theme => ({
  color: theme.vars.palette.action.active,
  padding: 2,
  margin: 2,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 20,
  height: 20,
  '& >svg': {
    width: 16,
    height: 16,
  },
})

export interface ChannelHeaderProps {
  className?: string
  selected?: Track
  onToggleDetails(): void
  onNewTrack?(): void
  onTogglePool?(): void
  onDeleteTrack?(track: Track): void
}

export const ChannelHeader: FC<ChannelHeaderProps> = props => {
  const { className, selected, onTogglePool, onDeleteTrack, onNewTrack, onToggleDetails } = props

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
      }}
      className={className}
    >
      <ButtonBase sx={actionButtonSx} title="Show/Hide Media Pool" onClick={onTogglePool}>
        <Pool />
      </ButtonBase>
      <ButtonBase sx={actionButtonSx} title="Show/Hide Details" onClick={() => onToggleDetails()}>
        <Preview />
      </ButtonBase>
      <ButtonBase sx={actionButtonSx} title="New Track" onClick={onNewTrack}>
        <Add />
      </ButtonBase>
      {selected && (
        <ButtonBase
          sx={{
            ...actionButtonSx,
            color: theme => theme.vars.palette.error.main,
          }}
          title="Delete Track"
          onClick={onDeleteTrack ? () => onDeleteTrack(selected) : undefined}
        >
          <Delete />
        </ButtonBase>
      )}
    </Box>
  )
}

export default ChannelHeader
