import type { Media, Persisted } from '@tunasong/schemas'
import { create } from 'zustand'

export interface PlaylistTrack {
  media: Pick<Persisted<Media>, 'id' | 'name' | 'storageUrls'>

  /** The source of the media - e.g., a playlist - to be used for a backlink */
  sourceEntityId?: string
}

/** Global state. We want to render `formDialog` on top (app) level */
export const useGlobalPlayerState = create<{
  playlist: PlaylistTrack[]
  currentSrc?: string
  active?: PlaylistTrack
  setPlayList: (playlist: PlaylistTrack[], active?: PlaylistTrack) => void
  setActive: (media: PlaylistTrack) => void
  playerRef: HTMLAudioElement | null
  setPlayerRef: (playerRef: HTMLAudioElement | null) => void
}>(set => ({
  playlist: [],
  playerRef: null,
  setActive: (track: PlaylistTrack) => set({ active: track, currentSrc: track.media?.storageUrls?.url }),
  setPlayerRef: (playerRef: HTMLAudioElement | null) => set({ playerRef }),
  setPlayList: (playlist: PlaylistTrack[]) => set({ playlist }),
}))
