import { Popper, Typography, styled } from '@mui/material'
import { formatNumber } from '@tunasong/models'
import { useDragMove } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback, useState } from 'react'

const getLeftWidth = (pan = 0) => `${pan < 0 ? Math.abs(pan) * 100 : 0}%`
const getRightWidth = (pan = 0) => `${pan > 0 ? Math.abs(pan) * 100 : 0}%`
const getLabel = (pan = 0) => `${formatNumber(Math.abs(pan) * 100, { digits: 1 })} ${pan < 0 ? 'L' : 'R'}`

export interface ChannelPanProps {
  className?: string
  /** From -1 to 1, 0 is center */
  pan?: number
  onPan(pan: number): void
}

const PanContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
})

const CenterContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  marginRight: theme.spacing(0.5),
  height: theme.spacing(0.5),
  width: theme.spacing(0.5),
  backgroundColor: theme.vars.palette.secondary.light,
}))

const LeftContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.vars.palette.action.hover,
  cursor: 'pointer',
  marginRight: theme.spacing(0.5),
  height: theme.spacing(0.5),
  flex: 1,
  transform: 'rotateY(180deg)',
}))

const RightContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  marginRight: theme.spacing(0.5),
  height: theme.spacing(0.5),
  width: theme.spacing(0.5),
  backgroundColor: theme.vars.palette.secondary.light,
  flex: 1,
}))

interface PanBarProps {
  panValue?: number
}

const LeftPanBar = styled('div')<PanBarProps>(({ theme, panValue = 0 }) => ({
  width: getLeftWidth(panValue),
  backgroundColor: theme.vars.palette.primary.light,
  height: theme.spacing(0.5),
}))

const RightPanBar = styled('div')<PanBarProps>(({ theme, panValue = 0 }) => ({
  width: getRightWidth(panValue),
  backgroundColor: theme.vars.palette.primary.light,
  height: theme.spacing(0.5),
}))

export const ChannelPan: FC<ChannelPanProps> = props => {
  const { className, pan = 0, onPan } = props

  /** Dragging the volume bar? */
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handlePan = useCallback(
    (ev: MouseEvent) => {
      if (!anchorEl) {
        return
      }
      ev.preventDefault()
      const box = anchorEl.getBoundingClientRect()
      const pan = Math.max(0, Math.min(((ev.clientX - box.left) / box.width) * 2, 2)) - 1
      onPan(pan)
    },
    [onPan, anchorEl]
  )

  const handleStart = useCallback(
    (ev: MouseEvent) => {
      handlePan(ev)
      setShowPan(true)
    },
    [handlePan]
  )

  const handleStop = useCallback(
    (ev: MouseEvent) => {
      handlePan(ev)
      setShowPan(false)
    },
    [handlePan]
  )

  const [showPan, setShowPan] = useState(false)

  useDragMove({
    el: anchorEl,
    onMove: handlePan,
    onStart: handleStart,
    onStop: handleStop,
  })

  return (
    <PanContainer className={className} title={getLabel(pan)} ref={setAnchorEl}>
      <LeftContainer>
        <LeftPanBar panValue={pan} />
      </LeftContainer>
      <CenterContainer />
      <RightContainer>
        <RightPanBar panValue={pan} />
      </RightContainer>
      <Popper open={showPan} anchorEl={anchorEl} placement="top">
        <Typography variant="caption">{getLabel(pan)}</Typography>
      </Popper>
    </PanContainer>
  )
}

export default ChannelPan
