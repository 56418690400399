import type { FabProps, SxProps, Theme } from '@mui/material'
import { DialogContent, Fab } from '@mui/material'
import { Microphone } from '@tunasong/icons'
import type { Entity, Persisted } from '@tunasong/schemas'
import { Dialog, isMobileDevice, useUploadStoredEntity } from '@tunasong/ui-lib'
import { useState, type MouseEvent } from 'react'
import AudioRecorder from './audio-recorder.js'
import { getDefaultEntityName } from '@tunasong/models'

export interface RecordFabButtonProps extends Omit<FabProps, 'onClick' | 'position'> {
  parent?: Persisted<Entity>
  position?: 'fixed' | 'relative'
}

export const RecordFabButton = (props: RecordFabButtonProps) => {
  const { sx: providedSx, color = 'primary', size = 'medium', parent, position = 'relative', ...restProps } = props

  const { uploadNewEntity, isUploading } = useUploadStoredEntity()

  const [open, setOpen] = useState(false)

  const handleComplete = (audio: Blob) => {
    setOpen(false)
    uploadNewEntity(getDefaultEntityName('audio'), parent?.id ?? null, audio)
  }

  const sx = (
    position === 'fixed' ? { position: 'fixed', bottom: 64, right: 24, zIndex: 1000, ...providedSx } : providedSx
  ) satisfies SxProps<Theme> | undefined

  const handleClick = (ev: MouseEvent) => {
    ev.stopPropagation()
    setOpen(true)
  }

  return (
    <>
      <Dialog fullScreen={isMobileDevice()} open={open} onClose={() => setOpen(false)} title="Record Audio">
        <DialogContent>
          <AudioRecorder sx={{ height: '100%' }} parentEntity={parent} onComplete={handleComplete} />
        </DialogContent>
      </Dialog>
      <Fab disabled={isUploading} color={color} size={size} sx={sx} onClick={handleClick} {...restProps}>
        <Microphone />
      </Fab>
    </>
  )
}
