/** Playlist management */

import type { PlaylistTrack } from './global-player-state.hook.js'

export type Playlist = PlaylistTrack[]

export const playlistManager = ({ playlist, active }: { playlist: Playlist; active?: PlaylistTrack }) => {
  const queue = (track: PlaylistTrack | PlaylistTrack[]) => {
    const trackToAdd = Array.isArray(track) ? track : [track]

    // Remove from the playlist items that are re-added
    const filteredPlaylist = playlist.filter(a => !trackToAdd.some(b => a.media?.id === b.media?.id))

    return [...filteredPlaylist, ...trackToAdd]
  }

  const scheduleNow = (track: PlaylistTrack | PlaylistTrack[]) => {
    const m = Array.isArray(track) ? track : [track]
    const mIds = m.map(a => a.media.id)

    const retainList = playlist.filter(a => !mIds.includes(a.media.id))
    const activePos = active ? retainList.findIndex(a => a.media.id === active.media.id) : -1

    // Insert the media in the activePos in the retainList
    retainList.splice(activePos + 1, 0, ...m)

    return retainList
  }

  return {
    queue,
    scheduleNow,
  }
}
