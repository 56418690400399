import { Box, type BoxProps } from '@mui/material'
import type { AudioChannel } from '@tunasong/models'
import { logger } from '@tunasong/models'
import type { Clip } from '@tunasong/schemas'
import { useDragMove } from '@tunasong/ui-lib'
import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { useClipPlayer } from '../../hooks/clip-player.js'

export interface ClipProps extends Omit<BoxProps, 'ref'> {
  clip: Clip
  onUpdate?(clip: Clip): void
  onDelete?(clip: Clip): void
}
export interface ClipContainerProps extends ClipProps {
  className?: string
  channel: AudioChannel
  // height: number
  pixelsPerSecond: number
  Component: (props: ClipProps) => React.ReactNode
  ref?: React.Ref<HTMLDivElement>
}

export const ClipContainer: FC<ClipContainerProps> = props => {
  const { className, clip, channel, pixelsPerSecond, Component, onUpdate, onDelete, ...restProps } = props

  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  // useWhyDidYouUpdate('ClipContainer', props)

  /** Schedule the event on the timeline according to the clip start */
  useClipPlayer(channel, clip)

  const [dragStart, setDragStart] = useState<number | null>(null)
  const handleDragStart = useCallback(() => {
    setDragStart(clip.start)
  }, [clip])

  const handleDragStop = useCallback(() => {
    if (!dragStart) {
      logger.warn(`Drag stopped with no drag clip state`)
      return
    }
    const updatedClip = { ...clip, start: dragStart }
    if (!onUpdate) {
      return
    }
    onUpdate(updatedClip)
    setDragStart(null)
  }, [clip, dragStart, onUpdate])

  const handleDragMove = useCallback(
    (ev: globalThis.MouseEvent) => {
      if (!ref) {
        return
      }
      const deltaSeconds = ev.movementX / pixelsPerSecond
      const start = Math.max((dragStart ?? clip.start) + deltaSeconds, 0)
      ref.style.setProperty('left', `${start * pixelsPerSecond}px`)
      setDragStart(start)
    },
    [clip.start, dragStart, pixelsPerSecond, ref]
  )

  const isDragging = useDragMove({
    el: ref,
    onMove: handleDragMove,
    onStart: handleDragStart,
    onStop: handleDragStop,
  })

  const toggleSelect = useCallback(() => {
    ref?.focus()
  }, [ref])

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (onDelete && (ev.key === 'Backspace' || ev.key === 'Delete')) {
        onDelete(clip)
      }
    },
    [clip, onDelete]
  )

  const clipStart = dragStart ?? clip.start
  const clipDuration = clip.duration
  const clipStyle = useMemo(
    () => ({
      left: pixelsPerSecond * clipStart,
      // height: height - 8,
      width: pixelsPerSecond * clipDuration,
    }),
    [clipDuration, clipStart, pixelsPerSecond]
  )

  const isSelected = document.activeElement === ref

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        position: 'relative',
        margin: theme.spacing(1, 0, 1, 0),
        border: `1px solid ${theme.vars.palette.action.focus}`,
        borderRadius: theme.spacing(),
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
        background: isSelected ? theme.vars.palette.primary.light : 'transparent',
        color: isSelected ? theme.vars.palette.primary.contrastText : 'inherit',
      })}
      className={className}
      ref={setRef}
      style={clipStyle}
      onClick={toggleSelect}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      {...restProps}
    >
      <Component clip={clip} onUpdate={onUpdate} />
    </Box>
  )
}

export default ClipContainer
