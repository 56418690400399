import { Box, useTheme } from '@mui/material'
import type { BoxProps } from '@mui/material'
import type { AudioPeaks } from '@tunasong/models'
import { useContainerSize, useMergeRefs } from '@tunasong/ui-lib'
import { useEffect, useRef, useState } from 'react'
import type { ElementRef, Ref } from 'react'
import { drawOverlay, drawWaveform } from './wave-canvas-draw.js'
import { getWaveGradient } from './wave-gradient.js'

/** Wave render using a Canvas */
interface WaveProps extends Omit<BoxProps, 'position'> {
  className?: string
  /** `fixed`: render each peak one time. `dynamic` fill the available box, calculate peak characteristics  */
  mode?: 'fixed' | 'dynamic'
  peaks?: AudioPeaks
  badge?: string | null
  /** Position in the wave, from 0.0 to 1.0 */
  position?: number
  barWidth?: number
  gapWidth?: number
  barColor?: string
  waveType?: 'audio' | 'monitor'
  ref?: Ref<HTMLCanvasElement>
}

export const WaveCanvas = (props: WaveProps) => {
  const {
    className,
    peaks,
    barWidth = 2,
    gapWidth = 1,
    badge,
    waveType = 'audio',
    position = 0,
    ref: rootRef,
    ...restProps
  } = props
  const theme = useTheme()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [containerRef, setContainerRef] = useState<ElementRef<'div'> | null>(null)
  const ref = useMergeRefs(rootRef, canvasRef)

  const size = useContainerSize(containerRef)

  const waveGradient = getWaveGradient({ theme, type: waveType })

  const lastPosRef = useRef(0)
  useEffect(() => {
    const canvas = canvasRef.current

    if (!(canvas && peaks && size?.height && size?.width)) {
      return
    }
    const id = requestAnimationFrame(() => {
      if (position < lastPosRef.current || lastPosRef.current === 0) {
        drawWaveform({
          canvas,
          barWidth,
          gapWidth,
          linePercent: 0.5,
          waveGradient,
          maxVal: 1,
          dataSamples: Array.from(peaks.data),
          height: size.height,
          width: size.width,
        })
      }
      drawOverlay({
        from: 0,
        to: position,
        /** @note we cannot use theme.vars here because we do math on the value. This color should be the same in dark and light anyway */
        fill: theme.palette.success.light,
        canvas,
      })
      lastPosRef.current = position
    })
    return () => cancelAnimationFrame(id)
  }, [barWidth, gapWidth, peaks, position, size?.height, size?.width, theme, waveGradient])

  return (
    <Box className={className} sx={{ overflow: 'hidden', flex: 1 }} {...restProps} ref={setContainerRef}>
      {badge ? (
        <Box
          sx={{
            display: 'block',
            fontSize: 11,
            padding: theme.spacing(0, 1, 0, 1),
            position: 'absolute',
            zIndex: 1,
            backgroundColor: theme.vars.palette.secondary.main,
            color: theme.vars.palette.primary.contrastText,
          }}
        >
          {badge}
        </Box>
      ) : null}
      <canvas ref={ref} style={{ width: '100%', height: '100%' }} />
    </Box>
  )
}

export default WaveCanvas
