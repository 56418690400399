import { useTheme } from '@mui/material'
import { isPositionEvent } from '@tunasong/schemas'
import { formatTime } from '../../lib/index.js'
import type { RendererPlugin } from '../renderer.js'
import { LabelText } from './styles.js'

const Label: RendererPlugin = ({ laneHeight, event, pixelsPerSecond }) => {
  const theme = useTheme()

  if (!isPositionEvent(event)) {
    return null
  }

  const labelPadding = 4
  const label = formatTime(event.start, { millis: false, minutePrefix: '' })

  const x = event.start * pixelsPerSecond + labelPadding
  const y = laneHeight

  return (
    <LabelText x={x} y={y} fill={theme.vars.palette.text.primary}>
      {label}
    </LabelText>
  )
}

export default Label
