import { logger } from '@tunasong/models'
import { Mutex } from 'async-mutex'
import invariant from 'tiny-invariant'
import { BTMidiInputAdapter } from './input-adapter.js'
import { BtMidiOutputAdapter } from './output-adapter.js'

// Device service identifier for MIDI
const MIDI_SERVICE_UID = '03B80E5A-EDE8-4B33-A751-6CE34EC4C700'.toLowerCase()
const MIDI_IO_CHARACTERISTIC_UID = '7772E5DB-3868-4112-A1A9-F2669D106BF3'.toLowerCase()

export class BluetoothMidi {
  connected = false

  btDevices: BluetoothDevice[] = []
  btDeviceAdvertisements: Map<string, BluetoothAdvertisingEvent> = new Map()
  inputs: BTMidiInputAdapter[] = []
  outputs: BtMidiOutputAdapter[] = []

  watchMutex: Mutex

  onConnectionStatus: (midi: BluetoothMidi) => void

  constructor({
    onConnectionStatus,
    midiAutoConnect,
  }: {
    midiAutoConnect: boolean
    onConnectionStatus: (midi: BluetoothMidi) => void
  }) {
    this.watchMutex = new Mutex()

    this.onConnectionStatus = onConnectionStatus
    // list all the available Bluetooth devices

    this.getBtDevices().then(async devices => {
      this.btDevices = [...this.btDevices, ...devices]

      if (midiAutoConnect) {
        logger.info('Autoconnecting to Bluetooth MIDI devices', { devices })
        this.connectAllDevices()
      } else {
        logger.debug('Bluetooth MIDI autoconnect is disabled')
      }
    })
  }

  destroy() {
    for (const device of this.btDevices) {
      device.gatt?.disconnect()
    }
    for (const input of this.inputs) {
      input.destroy()
    }
    for (const output of this.outputs) {
      output.destroy()
    }
    this.inputs = []
    this.outputs = []
    this.btDevices = []
  }

  async pairBtMidi() {
    logger.debug('Searching for bluetooth devices for pairing', { navigator: navigator.bluetooth })
    const device = await navigator.bluetooth
      .requestDevice({
        filters: [
          {
            services: [MIDI_SERVICE_UID],
          },
        ],
      })
      .catch(error => {
        logger.error('Error connecting to bluetooth device', error)
      })

    if (!device?.gatt) {
      logger.error('No device found or cancelled', device)
      return null
    }

    this.btDevices = [...this.btDevices.filter(d => d.id !== device.id), device]

    logger.debug('Paired successfully with bluetooth device', { device, devices: this.btDevices })

    await this.connectDevice({ device, forceConnect: true })

    this.onConnectionStatus(this)

    return device
  }

  forgetDevice = async (device: BluetoothDevice) => {
    device.gatt?.disconnect()

    // @note edge does not support forget() because it does not support re-connect
    if (typeof device.forget === 'function') {
      await device.forget()
    }
    this.btDevices = this.btDevices.filter(d => d.id !== device.id)
    this.inputs = this.inputs.filter(i => i.deviceId !== device.id)
    this.outputs = this.outputs.filter(o => o.deviceId !== device.id)
    this.onConnectionStatus(this)
  }

  // sometimes device.gatt.connected is true, but the device is not really connected.
  // use forceConnect in that case
  connectDevice = async ({ device, forceConnect = false }: { device: BluetoothDevice; forceConnect?: boolean }) => {
    if (device.gatt?.connected && !forceConnect) {
      return device
    }

    logger.debug('Connecting to bluetooth device: ', device.name, device.gatt)

    const characteristic = await this.connectToGATTServer(device)
    if (!characteristic) {
      logger.warn(`Unable to start device ${device.name} ${device.id}`)
      return device
    }

    device.addEventListener('gattserverdisconnected', this.onDisconnected)

    const inputAdapter = new BTMidiInputAdapter(device, characteristic)
    this.inputs = [...this.inputs.filter(i => i.deviceId !== device.id), inputAdapter]
    const outputAdapter = new BtMidiOutputAdapter(device, characteristic)
    this.outputs = [...this.outputs.filter(o => o.deviceId !== device.id), outputAdapter]

    logger.debug('Connected to bluetooth device: ', device.name, device.gatt?.connected, this.outputs)

    return device
  }

  connectAllDevices = async () => {
    // Connect all devices. Some of the devices may not be connected, so the connectDevice promise will hang as it's waiting for the device to be connected.

    const devices = this.btDevices
    if (devices.length === 0) {
      // Nothing to connect
      return
    }
    let devicePromises = devices.map(device => this.connectDevice({ device }))
    do {
      try {
        const device = await Promise.any(devicePromises)
        logger.debug('Connected to Bluetooth Device ', device)
        devicePromises.splice(devices.indexOf(device), 1)
        // Update inputs and outputs
        this.onConnectionStatus(this)
      } catch (e) {
        // If all promises are rejected, Promise.any will throw an error so we need to return
        logger.warn('Could not connect Bluetooth MIDI devices', e, this.btDevices)
        return
      }
    } while (devicePromises.length > 0)
  }

  onDisconnected = async (event: Event) => {
    const device = event.target as BluetoothDevice

    invariant(device.gatt?.connected === false, 'Bluetooth device is still connected')

    logger.debug('Bluetooth device is disconnected. Reconnecting...', device, event)

    // Remove the device from the list
    device.removeEventListener('gattserverdisconnected', this.onDisconnected)

    // try to re-connect
    await this.connectDevice({ device, forceConnect: true })

    this.onConnectionStatus(this)
  }

  // on the device and wait for the first 'advertisementreceived' event to be fired before calling gatt.connect().
  private connectToGATTServer = async (device: BluetoothDevice) => {
    invariant(device.gatt, 'No GATT server found')

    // for autoconnect - see https://issues.chromium.org/issues/40167015
    await this.waitForAdvertisement(device)

    // const server = device.gatt?.connected ? device.gatt : await device.gatt.connect()
    const server = await device.gatt.connect()

    if (!server) {
      logger.warn(`No GATT server found for device ${device.name}`)
      return
    }

    const service = await server.getPrimaryService(MIDI_SERVICE_UID)

    const characteristic = await service.getCharacteristic(MIDI_IO_CHARACTERISTIC_UID)

    return characteristic
  }

  private getBtDevices = async () => {
    if (typeof navigator.bluetooth?.getDevices === 'undefined') {
      logger.warn('Web Bluetooth getDevices API is not available')
      return []
    }
    /**
     * Must be enabled in Chrome: chrome://flags/#enable-web-bluetooth-new-permissions-backend
     *
     * https://googlechrome.github.io/samples/web-bluetooth/get-devices.html
     */
    const devices = await navigator.bluetooth.getDevices()
    return devices
  }
  private waitForAdvertisement = async (device: BluetoothDevice) => {
    // @see https://issues.chromium.org/issues/40167015
    // For "Bluetooth Device is no longer in range"

    // If we have received an advertisement, we don't need to wait
    if (this.btDeviceAdvertisements.has(device.id)) {
      return
    }

    if (typeof device.watchAdvertisements === 'function') {
      logger.debug('Waiting for advertisements for device', device, this.btDeviceAdvertisements)
      const release = await this.watchMutex.acquire()
      return new Promise<void>(resolve => {
        if (!device.watchingAdvertisements) {
          device.watchAdvertisements()
        }
        const handleReceived = (event: Event) => {
          logger.debug('Received advertisement for device', device)
          this.btDeviceAdvertisements.set(device.id, event as BluetoothAdvertisingEvent)

          device.removeEventListener('advertisementreceived', handleReceived)
          release()
          resolve()
        }
        device.addEventListener('advertisementreceived', handleReceived)
      })
    }
  }
}
