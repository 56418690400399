import { isAudioClip } from '@tunasong/schemas'
import type { EntityClip, Track } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'
import { features, useThunkDispatch } from '@tunasong/redux'
import { useCallback, useRef } from 'react'
import { useMixer } from '../../hooks/index.js'
import { logger } from '@tunasong/models'
import { graphHooks } from '@tunasong/graph-lib/react'

/** Upload audio clips that are stored as blobs in memory  */
export const useUploadTrackClips = (parentId: string) => {
  const mixer = useMixer()
  const dispatch = useThunkDispatch()
  const updateEntity = graphHooks.useEntityUpdate<Track>({ debounceDelay: 0 })

  const isUpdating = useRef(false)
  const uploadClips = useCallback(
    async (track: Persisted<Track>) => {
      if (isUpdating.current || !mixer) {
        return
      }

      /** Update track settings from mixer */
      const channel = mixer.getChannel(track.id)
      if (!channel) {
        throw new Error(`Cannot get channel for track ${track.id}`)
      }

      const clips = [...(track.clips ?? [])]
      let hasUpdatedClips = false
      isUpdating.current = true

      try {
        for (const clip of clips) {
          if (!(isAudioClip(clip) && clip.url.startsWith('blob:'))) {
            continue
          }
          hasUpdatedClips = true
          logger.debug('Clip is new', clip.url)
          const blob = await fetch(clip.url).then(async r => r.blob())

          const result = await dispatch(
            features.storage.thunks.uploadStoredEntity({ name: track.name, type: 'audio', parentId, blob })
          ).unwrap()
          logger.debug(result)
          /** Now we must replace the clip with the new data */
          const clipIdx = clips.indexOf(clip)
          const newClip: EntityClip = { ...clip, type: 'entityclip', entityId: result.id }

          clips[clipIdx] = newClip
        }
        if (hasUpdatedClips) {
          updateEntity(track.id, { clips })
        }
      } catch (e) {
        logger.warn(`Failed to update track`, e)
      } finally {
        isUpdating.current = false
      }
    },
    [dispatch, mixer, parentId, updateEntity]
  )
  return uploadClips
}

export default useUploadTrackClips
