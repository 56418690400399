import { Typography } from '@mui/material'
import type { TypographyProps } from '@mui/material'
import { ChordLib } from '@tunasong/music-lib'
import type { ChordVariant } from '@tunasong/music-lib'
import { HBox } from '@tunasong/ui-lib'
import { useCallback, useEffect, useRef } from 'react'
import type { FC, ElementRef } from 'react'
import { AudioController } from '../engine/index.js'

export interface ChordViewProps extends TypographyProps {
  audioController: AudioController
}

export const ChordView: FC<ChordViewProps> = props => {
  const { audioController, ...typographyProps } = props

  const ref = useRef<ElementRef<'div'>>(null)

  const handleChord = useCallback((ev: CustomEvent<ChordVariant | null>) => {
    /** If the chroma processor is not enabled we can get a null message here if other processors */
    if (!ref.current) {
      return
    }
    const chord = ev.detail

    if (!chord) {
      ref.current.innerText = '-'
      return
    }

    ref.current.innerText = ChordLib.getName(chord)
  }, [])

  useEffect(() => {
    audioController.enableDSPFeature('chord', true)
    audioController.addFeatureListener('chord', handleChord)

    return () => {
      /** @todo what if others require chroma? */
      /** @todo perhaps we should automatically check if we have  */
      audioController.enableDSPFeature('chord', false)
      audioController.removeFeatureListener('chord', handleChord)
    }
  }, [audioController, handleChord])

  return (
    <HBox justifyContent="center">
      <Typography
        ref={ref}
        {...typographyProps}
        sx={{ ...typographyProps.sx, color: theme => theme.vars.palette.primary.main }}
      />
    </HBox>
  )
}

export default ChordView
