import type { StackProps } from '@mui/material'
import { Box, Fab, Stack, Typography } from '@mui/material'
import type { VirtualElement } from '@popperjs/core'
import { Next, Play as PlayIcon, Prev, Stop as StopIcon } from '@tunasong/icons'
import type { AudioPosition } from '@tunasong/models'
import { Popup, useHotkey } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useTimelineInteraction } from '../hooks/index.js'
import { trackMouse } from '../lib/index.js'
import { PlayerProgress } from '../progress.js'
import { useGlobalPlayer } from './global-player.hook.js'

export interface PlayerControlsProps extends Omit<StackProps, 'onSelect' | 'ref'> {
  // Name of the audio channel
  name?: string
  title?: string
  /** Capture keyboard events to control start/stop, next, prev */
  keyboardControl?: boolean
  // onSelect(idx: number): void
  onSeek?(pos: number | AudioPosition): void
}

export const PlayerControls: FC<PlayerControlsProps> = props => {
  const { title, onSeek, keyboardControl = false, ...restProps } = props

  const { controls, playlist, isPlaying, progressLabel, duration, playerRef } = useGlobalPlayer()

  const [popupEl, setPopupEl] = useState<VirtualElement | null>(null)

  /** Track user interaction */
  const { position, pagePosition, timelineRef } = useTimelineInteraction({
    duration,
    onSeek,
  })

  useEffect(() => {
    setPopupEl(pagePosition ? trackMouse(pagePosition) : null)
  }, [pagePosition])

  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useHotkey({ hotkey: 'ArrowLeft', handler: playlist.prev, disabled: !keyboardControl, el: ref })
  useHotkey({ hotkey: 'ArrowRight', handler: playlist.next, disabled: !keyboardControl, el: ref })
  useHotkey({ hotkey: 'Space', handler: controls.togglePlay, disabled: !keyboardControl, el: ref })

  return (
    <>
      <Stack
        {...restProps}
        ref={setRef}
        spacing={2}
        sx={{ ...restProps.sx, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack direction="row" spacing={2}>
          <Fab color="primary" disabled={!playlist.prev} size={'medium'} title={title} onClick={playlist.prev}>
            <Prev />
          </Fab>
          <Fab
            size={'medium'}
            title={title}
            disabled={!playlist.active}
            onClick={controls.togglePlay}
            color="secondary"
          >
            {isPlaying ? <StopIcon /> : <PlayIcon />}
          </Fab>
          <Fab color="primary" disabled={!playlist.next} size={'medium'} title={title} onClick={playlist.next}>
            <Next />
          </Fab>
        </Stack>

        <PlayerProgress ref={timelineRef} el={playerRef} />

        <Box display="flex" flexDirection="row" flex={1} mt={1}>
          <Typography variant="caption">{playlist.active?.media?.name}</Typography>
          <Box ml={1} />
          <Typography variant="caption">{progressLabel}</Typography>
        </Box>
      </Stack>
      <Popup open={Boolean(popupEl)} anchorEl={popupEl} placement="right-end">
        <Typography variant="caption">{position?.label}</Typography>
      </Popup>
    </>
  )
}

export default PlayerControls
