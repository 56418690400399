import type { ListItemProps, TypographyProps } from '@mui/material'
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import { Mixer } from '@tunasong/icons'
import { Dialog } from '@tunasong/ui-lib'
import React, { useCallback, useState } from 'react'
import { AudioMixer } from './audio-mixer.js'

export interface MixerMenuItemProps extends Omit<ListItemProps, 'button'> {
  variant?: TypographyProps['variant']
}

export const useMixerMenuItem = (props: MixerMenuItemProps = {}) => {
  const { onClick, variant = 'body1', ...restProps } = props

  const [showMixer, setShowMixer] = useState(false)
  const handleShowMixer = (ev: React.MouseEvent<HTMLLIElement>) => {
    ev.preventDefault()
    if (onClick) {
      onClick(ev)
    }
    setShowMixer(true)
  }
  const handleHideMixer = useCallback(() => setShowMixer(false), [])

  return {
    dialog: (
      <Dialog open={showMixer} onClose={handleHideMixer} title="Mixer" fullWidth={true} maxWidth="sm">
        <AudioMixer />
      </Dialog>
    ),
    menuItem: (
      <MenuItem title="Room Audio/Video" color="inherit" onClick={handleShowMixer} {...restProps}>
        <ListItemIcon>
          <Mixer />
        </ListItemIcon>
        <ListItemText>
          <Typography variant={variant}>Mixer</Typography>
        </ListItemText>
      </MenuItem>
    ),
  }
}
