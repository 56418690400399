import { Box, styled } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { shortUuid } from '@tunasong/models'
import type { AudioPeaks } from '@tunasong/models'
import { isSafari, useContainerSize, useMergeRefs } from '@tunasong/ui-lib'
import React, { useEffect, useMemo, useState } from 'react'
import { generateBars } from '../lib/wave-drawer.js'

interface ClipPathProps {
  clipPathUrl?: string
}

const Badge = styled('div')(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(0, 1, 0, 1),
  position: 'absolute',
  zIndex: 1,
  backgroundColor: theme.vars.palette.primary.light,
  color: theme.vars.palette.primary.contrastText,
}))

const WaveformContainer = styled('svg')({
  flex: 1,
})

const WaveformBackground = styled('rect')<ClipPathProps>(({ theme, clipPathUrl }) => ({
  clipPath: clipPathUrl,
  fill: theme.vars.palette.primary.light,
}))

const WaveformProgress = styled('rect')<ClipPathProps>(({ theme, clipPathUrl }) => ({
  clipPath: clipPathUrl,
  fill: theme.vars.palette.success.main,
}))

export interface WaveProps extends Omit<BoxProps, 'position'> {
  className?: string
  /** `fixed`: render each peak one time. `dynamic` fill the available box, calculate peak characteristics  */
  mode?: 'fixed' | 'dynamic'
  peaks?: AudioPeaks
  badge?: string | null
  /** Position in the wave, from 0.0 to 1.0 */
  position?: number
  barWidth?: number
}

export const Wave = (props: WaveProps) => {
  const { className, badge, peaks, barWidth = 5, position = 0, mode = 'dynamic', ref: rootRef, ...restProps } = props
  const clipId = useMemo(() => `waveform-${shortUuid()}`, [])
  const clipPath = `#${clipId}`
  const clipPathUrl = `url("${clipPath}")`
  const progressWidth = position * 100

  const [localRef, setLocalRef] = useState<HTMLElement | null>(null)
  const mergedRef = useMergeRefs(setLocalRef, rootRef)
  const maxBuckets = mode === 'fixed' ? (peaks?.length ?? 0) : Math.min(peaks?.length ?? 200, 200)

  const spacing = 0.2

  const containerSize = useContainerSize(localRef)
  const numBuckets =
    mode === 'fixed'
      ? (peaks?.length ?? 0)
      : containerSize?.width
        ? Math.min(maxBuckets, containerSize.width / (barWidth + spacing))
        : 0

  const bars = peaks ? generateBars({ peaks, numBuckets, spacing }) : []

  /** Hack to enable wave is rendering on Safari */
  const [renderKey, setRenderKey] = useState(shortUuid())
  useEffect(() => {
    if (!isSafari()) {
      return
    }
    setTimeout(() => {
      setRenderKey(shortUuid())
    }, 1000)
  }, [])

  return (
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        fontSize: 12,
      }}
      {...restProps}
      className={className}
      ref={mergedRef}
    >
      {badge ? <Badge>{badge}</Badge> : null}

      <WaveformContainer viewBox="0 0 100 100" preserveAspectRatio="none">
        <WaveformBackground clipPathUrl={clipPathUrl} x="0" y="0" height="100" width="100" />
        <WaveformProgress clipPathUrl={clipPathUrl} x={0} y={0} height={100} width={progressWidth} />
      </WaveformContainer>
      {/* this SVG is the "clipping mask" - the waveform bars */}
      <svg height="0" width="0">
        <defs>
          <clipPath key={renderKey} id={clipId}>
            {bars}
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export default Wave
