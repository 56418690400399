/** Global playlist management */

import type { Media, Persisted } from '@tunasong/schemas'
import { useEffect } from 'react'
import invariant from 'tiny-invariant'
import { useGlobalPlayerState } from './global-player-state.hook.js'
import { usePlayerControl } from './player-control.hook.js'
import { playlistManager } from './playlist-manager.js'

export const useGlobalPlayer = ({
  initialPlaylist,
  sourceEntityId,
}: { initialPlaylist?: Persisted<Media>[]; sourceEntityId?: string } = {}) => {
  const { playlist, setPlayList, playerRef, setActive, active } = useGlobalPlayerState()

  useEffect(() => {
    if (!initialPlaylist) {
      return
    }

    // Is it the same playlist? If so, do nothing
    if (playlist.length === initialPlaylist.length && playlist.every((m, i) => m.media.id === initialPlaylist[i].id)) {
      return
    }

    setPlayList(initialPlaylist.map(media => ({ media, sourceEntityId })))
    setActive({ media: initialPlaylist[0], sourceEntityId })
  }, [initialPlaylist, playlist, setActive, setPlayList, sourceEntityId])

  const handleSelect = (num: number) => {
    setActive(playlist[num])
    play()
  }

  const {
    playing: isPlaying,
    play,
    togglePlay,
    pause,
    progressLabel,
    seek,
    position,
    next,
    prev,
    duration,
  } = usePlayerControl({
    el: playerRef,
    media: playlist.map(p => p.media),
    active: active?.media,
    onSelect: handleSelect,
  })

  const queue = (media: Persisted<Media>) => {
    setPlayList(playlistManager({ playlist, active }).queue({ media, sourceEntityId }))
  }

  /** Schedule play of the current song now */
  const playNow = (track: Persisted<Media> | Persisted<Media>[]) => {
    const mediaList = Array.isArray(track) ? track : [track]
    const trackList = mediaList.map(media => ({ media, sourceEntityId }))

    setPlayList(playlistManager({ playlist, active }).scheduleNow(trackList))
    const activeTrack = {
      media: Array.isArray(track) ? track[0] : track,
      sourceEntityId,
    }
    setActive(activeTrack)
    play()
  }

  // Activate a media item already in a playlist and play it
  const activate = (media: Persisted<Media>) => {
    invariant(
      playlist.some(m => m.media?.id === media?.id),
      'Media item must be in the playlist'
    )
    setActive({ media, sourceEntityId })
    play()
  }

  return {
    controls: {
      pause,
      seek,
      play,
      togglePlay,
    },
    playlist: {
      tracks: playlist,
      queue,
      playNow,
      activate,
      active,
      next,
      prev,
    },
    isPlaying,
    progressLabel,
    position,
    duration,
    playerRef,
  }
}
