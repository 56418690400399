import type { Media, Persisted } from '@tunasong/schemas'
import { useCallback, useEffect, useState } from 'react'
import type { PlayerProps } from './audio-player.js'
import { useAudioPlayer } from './audio-player.js'
import { getDurationLabel } from '../duration/index.js'

interface PlayerControl extends PlayerProps {
  el: HTMLAudioElement | null
  name?: string
  active?: Pick<Persisted<Media>, 'id' | 'name' | 'storageUrls'>
  media: Pick<Persisted<Media>, 'id' | 'name' | 'storageUrls'>[]
  onSelect(idx: number): void
}

export const usePlayerControl = (props: PlayerControl) => {
  const { active, onSelect, el, media, ...restProps } = props

  const audioPlayer = useAudioPlayer({
    el: el ?? null,
    ...restProps,
  })

  const activeIdx = active ? media.findIndex(m => m.id === active.id) : -1
  const hasPrev = activeIdx > 0
  const hasNext = activeIdx >= 0 && activeIdx < media.length - 1

  const handlePrev = useCallback(() => {
    if (!hasPrev) {
      return
    }
    onSelect(activeIdx - 1)
  }, [activeIdx, hasPrev, onSelect])

  const handleNext = useCallback(() => (hasNext ? onSelect(activeIdx + 1) : undefined), [activeIdx, hasNext, onSelect])

  /** The Audio element may not be ready to play when we invoke play(). Hence, we set autoPlay to start when it's ready */
  const [autoPlay, setAutoPlay] = useState(false)

  const handleEnded = useCallback(() => {
    if (hasNext) {
      setAutoPlay(true)
      handleNext()
    }
  }, [handleNext, hasNext])

  // Trigger a start when we're already playing
  const handleStart = useCallback(() => {
    if (autoPlay) {
      audioPlayer.play()
    }
  }, [autoPlay, audioPlayer])

  const play = useCallback(() => {
    setAutoPlay(true)
    audioPlayer.play()
  }, [audioPlayer])

  const pause = useCallback(() => {
    setAutoPlay(false)
    audioPlayer.pause()
  }, [audioPlayer])

  const togglePlay = useCallback(() => {
    // If we're paused, toggle will play.
    if (!audioPlayer.playing) {
      setAutoPlay(true)
    }
    audioPlayer.togglePlay()
  }, [audioPlayer])

  /** Events from the player */
  useEffect(() => {
    if (!el) {
      return
    }
    el.addEventListener('ended', handleEnded)
    el.addEventListener('canplay', handleStart)

    return () => {
      el.removeEventListener('ended', handleEnded)
      el.removeEventListener('canplay', handleStart)
    }
  }, [el, handleEnded, handleStart])

  return {
    ...audioPlayer,
    play,
    pause,
    togglePlay,
    progressLabel: `${getDurationLabel(el?.currentTime ?? 0)}/${getDurationLabel(audioPlayer.duration)}`,
    prev: hasPrev ? handlePrev : undefined,
    next: hasNext ? handleNext : undefined,
  }
}
