import { Box, type BoxProps } from '@mui/material'
import { useAnimationFrameLoop } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback, useRef } from 'react'
import { AudioController } from './engine/audio-controller.js'
import { getDBPercentage } from './lib/db.js'

export interface MeterProps extends BoxProps {
  className?: string
  controller?: AudioController
  at?: 'pre' | 'post'
  color?: 'primary' | 'secondary'
  orientation?: 'horizontal' | 'vertical'
}

export const Meter: FC<MeterProps> = props => {
  const { controller, at = 'pre', color = 'secondary', className, orientation = 'horizontal', sx, ...restProps } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const updateMeter = useCallback(() => {
    if (!(controller && ref.current)) {
      return
    }
    const db = controller.getDB(at)
    const size = `${getDBPercentage({ value: db.peak, reference: 0 })}%`
    if (orientation === 'horizontal') {
      ref.current.style.width = size
    } else {
      ref.current.style.height = size
    }
  }, [at, controller, orientation])

  useAnimationFrameLoop(updateMeter)

  if (!controller) {
    return null
  }

  /** We need this to be performant, so we don't keep state in React here */
  return (
    <Box
      sx={{
        flexDirection: orientation === 'horizontal' ? 'row' : 'column',
        height: theme => (orientation === 'horizontal' ? theme.spacing(0.75) : '100%'),
        width: theme => (orientation === 'vertical' ? theme.spacing(0.75) : '100%'),
        position: 'relative',
        backgroundColor: theme =>
          color === 'primary' ? theme.vars.palette.primary.light : theme.vars.palette.secondary.light,
        ...sx,
      }}
      className={className}
      ref={ref}
      {...restProps}
    />
  )
}

export default Meter
