import type { AudioPeaks } from '@tunasong/models'
import { storageApi } from '@tunasong/redux'
import type { Audio } from '@tunasong/schemas'
import invariant from 'tiny-invariant'
import { useRenderPeaks } from './render-peaks.js'

interface Peaks {
  media?: Audio
  /** Get the peaks from this url */
  url?: string
  /** Render Peaks if the peaks are not available */
  renderPeaks?: boolean
}

export const usePeaks = ({ media, url: sourceUrl, renderPeaks = false }: Peaks) => {
  let peaksPath = media?.features?.peaks?.data

  invariant(!(sourceUrl && media), 'Cannot provide both media and url')

  /** Legacy peaks path */
  if (typeof peaksPath === 'object') {
    peaksPath = undefined
  }

  const mediaUrl = media?.storageUrls?.url

  const { currentData: loadedPeaks, isLoading: storageLoading } = storageApi.useGetFileJSONQuery(
    { storagePath: peaksPath ?? '' },
    { skip: Boolean(!peaksPath) }
  )

  const url = sourceUrl ?? mediaUrl

  /** If we don't have a peaksPath and wants to renderPeaks, use fallback method */
  const fallbackUrl = url && renderPeaks ? url : null
  const { peaks: renderedPeaks, loading: renderedLoading } = useRenderPeaks(fallbackUrl)

  const peaks = loadedPeaks ?? renderedPeaks
  const loading = storageLoading || renderedLoading

  return { peaks: peaks as AudioPeaks | undefined, loading }
}
