import { styled } from '@mui/material'

// SVG elements are now handled with the styled API directly
// The components below replace the old CSS class names

/**
 * @deprecated Use the styled components directly instead of classes
 * This is only kept for backward compatibility with existing code
 */
export default () => ({
  classes: {
    root: 'root',
    label: 'rendererLabel',
    badge: 'rendererBadge',
    marker: 'rendererMarker',
    markerText: 'rendererMarkerText',
    loop: 'rendererLoop',
    loopHandle: 'rendererLoopHandle',
  },
})
export const LabelText = styled('text')({
  fontSize: 10,
  userSelect: 'none',
})

export const Badge = styled('text')(({ theme }) => ({
  fontSize: 10,
  color: theme.vars.palette.primary.contrastText,
  backgroundColor: theme.vars.palette.primary.main,
}))

export const Marker = styled('g')({
  '&:hover': {
    cursor: 'pointer',
  },
})

export const MarkerText = styled('text')(({ theme }) => ({
  color: theme.vars.palette.secondary.contrastText,
}))

export const Loop = styled('g')({
  '&:hover': {
    cursor: 'ew-resize',
  },
})

export const LoopHandle = styled('rect')({
  cursor: 'pointer',
})
