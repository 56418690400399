import { Box, styled, type BoxProps } from '@mui/material'

export const RecordingClip = (props: BoxProps) => (
  <Box
    {...props}
    sx={theme => ({
      // ...props.sx,
      display: 'flex',
      position: 'relative',
      height: '100%',
      margin: theme.spacing(1, 0, 1, 0),
      backgroundColor: theme.vars.palette.secondary.light,
      border: `1px solid ${theme.vars.palette.action.focus}`,
      borderRadius: theme.spacing(),
      opacity: 0.5,
    })}
  />
)

export const RecordingWav = styled('div')({
  flex: 1,
  display: 'flex',
  height: '100%',
})

export const WaveContainer = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
})
