import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'
import type { ButtonProps } from '@mui/material'
import { Button, CircularProgress } from '@mui/material'
import type { Rhythm } from '@tunasong/models'
import { getBeatInterval } from '@tunasong/models'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

export interface RecordingButtonProps extends ButtonProps {
  className?: string

  label?: string

  // If set, will show a count down for the specified number of seconds before starting recording
  countDownSeconds?: number | number

  rhythm?: Rhythm
  onStart(): void
  onStop(): void
}

const RecordingButton: FC<RecordingButtonProps> = props => {
  const { onStart, onStop, countDownSeconds, label = 'Record', rhythm, ...restProps } = props

  const [recording, setRecording] = useState(false)

  const [countDown, setCountDown] = useState(-1)
  const interval = useRef<NodeJS.Timeout | null>(null)

  const handleStart = useCallback(() => {
    setRecording(true)
    if (onStart && !interval.current) {
      onStart()
    }
  }, [onStart])

  const handleScheduleStart = () => {
    if (!countDownSeconds) {
      handleStart()
      return
    }
    setCountDown(rhythm?.meter?.notesPerBar ?? 4)
    const beatInterval = getBeatInterval(rhythm?.tempo)
    const int = setInterval(() => {
      setCountDown(count => {
        const cnt = count - 1
        return cnt
      })
    }, beatInterval)
    interval.current = int
  }

  useEffect(() => {
    if (countDown === 0) {
      setCountDown(-1)
      if (interval.current) {
        clearInterval(interval.current)
        interval.current = null
      }
      handleStart()
    }
  }, [countDown, handleStart])

  const handleStop = useCallback(() => {
    setRecording(false)
    if (onStop) {
      onStop()
    }
  }, [onStop])

  const showLabel = countDown > 0 ? `Starting in ${countDown}...` : recording ? 'Recording...' : label

  return (
    <Button
      onClick={recording ? handleStop : handleScheduleStart}
      startIcon={recording ? <CircularProgress color="error" size={16} /> : <KeyboardVoiceIcon />}
      autoFocus={true}
      {...restProps}
    >
      {showLabel}
    </Button>
  )
}

export default RecordingButton
