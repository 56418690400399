import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import type { ListItemProps, TypographyProps } from '@mui/material'
import { Tuner } from '@tunasong/icons'
import { Dialog } from '@tunasong/ui-lib'
import { useState } from 'react'
import type { MouseEvent } from 'react'
import { TunerView } from './dsp/index.js'
import { useAudioEngine } from './hooks/index.js'

export interface TunerMenuItemProps extends Omit<ListItemProps, 'button'> {
  onClick?: () => void
  variant?: TypographyProps['variant']
}

export const useTunerMenuItem = (props: TunerMenuItemProps = {}) => {
  const { onClick, variant, ...restProps } = props

  const [showTuner, setShowTuner] = useState(false)

  const engine = useAudioEngine()

  const enableMonitor = (monitor = true) => {
    const input = engine.defaultInput
    if (!input) {
      return
    }
    input.enableDSPFeature('tuner', monitor)

    // eslint-disable-next-line react-compiler/react-compiler
    input.monitor = monitor
  }

  const handleShowTuner = (ev: MouseEvent) => {
    ev.preventDefault()
    enableMonitor(true)
    setShowTuner(true)
    if (onClick) {
      onClick()
    }
  }

  const handleHideTuner = () => {
    enableMonitor(false)
    setShowTuner(false)
  }

  return {
    dialog: (
      <Dialog open={showTuner} onClose={handleHideTuner} title="Tuner" fullWidth={true} maxWidth="xs">
        {engine.defaultInput ? <TunerView audioController={engine.defaultInput} showInput={true} /> : null}
        {!engine.defaultInput ? <Typography color="error">No input device available</Typography> : null}
      </Dialog>
    ),
    menuItem: (
      <MenuItem title="Tuner" color="inherit" onClick={handleShowTuner} {...restProps}>
        <ListItemIcon>
          <Tuner />
        </ListItemIcon>
        <ListItemText>
          <Typography variant={variant}>Tuner</Typography>
        </ListItemText>
      </MenuItem>
    ),
  }
}
