import type { BoxProps } from '@mui/material'
import { Box, Button, Typography } from '@mui/material'
import { getDefaultEntityName, logger, shortUuid } from '@tunasong/models'
import type { Audio, Entity, Persisted } from '@tunasong/schemas'
import { isSong } from '@tunasong/schemas'
import { HBox, TunaEntityBreadcrumbs, VBox, useAlert } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useMixer, useRecorder, useStartMic, useTransport } from '../hooks/index.js'
import { Meter } from '../meter.js'
import RecordingButton from '../recording-button.js'
import { Time } from '../time.js'
import { Waveform } from '../waveform/waveform.js'
import { FFT } from './fft.js'

export interface RecorderProps extends BoxProps {
  className?: string
  /** The parent entity for the audio. If specified, the recording experience can be customized, e.g., count-in tempo for songs */
  parentEntity?: Persisted<Entity>
  showMeter?: boolean
  autoFocus?: boolean
  /** The maximum recording time, in seconds */
  maxSeconds?: number
  /** Use either MediaRecorder (Webm / Opus) or Webaudio worklet Wav recorder. @default stream */
  recorderType?: 'stream' | 'wav'
  /** Called during recording */
  onRecording?(recording: boolean): void
  onComplete(audio: Blob): void
}

const createTemporaryMedia = ({ url, parentId }: { url: string; parentId?: string }): Persisted<Audio> => ({
  id: shortUuid(),
  parentId,
  name: getDefaultEntityName('audio'),
  type: 'audio',
  userId: 'temporary-media',
  storageUrls: {
    url,
    expiresAtISODateTime: '2100-01-01T00:00:00.000Z',
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
})

export const AudioRecorder: FC<RecorderProps> = props => {
  const {
    className,
    parentEntity,
    showMeter = true,
    autoFocus = false,
    onRecording,
    onComplete,
    recorderType = 'stream',
    maxSeconds = 60 * 60,
    ...restProps
  } = props
  const mixer = useMixer()

  const { selectedDevice } = useStartMic()

  const mediaRecorder = useRecorder(recorderType)
  const { transport } = useTransport()
  const [audioData, setAudioData] = useState<Blob>()
  const [temporaryMedia, setTemporaryMedia] = useState<Persisted<Audio>>()
  const [isRecording, setIsRecording] = useState(false)

  const handleSave = (audioData: Blob) => () => {
    setAudioData(undefined)
    setTemporaryMedia(undefined)
    onComplete(audioData)
  }

  useEffect(() => {
    if (!onRecording) {
      return
    }
    onRecording(isRecording)
  }, [isRecording, onRecording])

  /** Example https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Recording_API/Using_the_MediaStream_Recording_API */
  const startRecording = async () => {
    if (!mediaRecorder) {
      throw new Error(`Cannot record without a mixer and a mediaRecorder`)
    }

    logger.debug(`Starting transport for recording. Recording type: ${recorderType}`)
    transport.start()
    mediaRecorder.start()

    setIsRecording(true)
  }

  const handleDelete = () => {
    setAudioData(undefined)
    setIsRecording(false)
  }

  const stopRecording = useCallback(() => {
    if (!mediaRecorder) {
      return
    }
    mediaRecorder.stop()
    transport.stop()

    const audioData = mediaRecorder.getRecording()

    logger.debug('Completed recording. Pending upload....')
    const url = URL.createObjectURL(audioData)
    setAudioData(audioData)

    setTemporaryMedia(createTemporaryMedia({ url, parentId: parentEntity?.id }))
    setIsRecording(false)
  }, [mediaRecorder, parentEntity?.id, transport])

  /** Stop the recording when maxSeconds has been recorded */
  const { alert } = useAlert()
  useEffect(() => {
    if (!isRecording) {
      return
    }
    const id = setTimeout(() => {
      alert({
        severity: 'warning',
        message: `Recording stopped after ${maxSeconds} seconds. This is currently the maximum recording time.`,
      })
      stopRecording()
    }, 1000 * maxSeconds)
    return () => clearTimeout(id)
  }, [alert, isRecording, maxSeconds, stopRecording])

  return (
    <VBox {...restProps}>
      {temporaryMedia && audioData ? (
        <>
          <Waveform media={temporaryMedia} renderPeaks={true} timeline={false} />
          <Box
            sx={{
              display: 'flex',
              my: 2,
              alignContent: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ pr: 2 }} variant="body2">
              Recording will be stored at
            </Typography>
            <TunaEntityBreadcrumbs entity={temporaryMedia} maxItems={10} />
          </Box>
          <HBox sx={{ justifyContent: 'space-around' }}>
            <Button color={'secondary'} onClick={handleDelete}>
              Discard
            </Button>
            <Button onClick={handleSave(audioData)} autoFocus={autoFocus}>
              Save
            </Button>
          </HBox>
        </>
      ) : (
        <>
          {selectedDevice ? <Typography variant="caption">{selectedDevice.label}</Typography> : null}
          <Box flex={1} />
          <VBox sx={{ minHeight: 140, maxHeight: 140, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <FFT
              sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
                color: theme => theme.vars.palette.secondary.dark,
              }}
            />
          </VBox>
          <Box flex={1} />

          {showMeter && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Meter controller={mixer?.getBus('record')} />
              <Box flex={1} />
              <Time startTime={mediaRecorder?.startTime} />
            </Box>
          )}
          <RecordingButton
            className={className}
            label="Start Recording"
            rhythm={isSong(parentEntity) ? parentEntity.rhythm : undefined}
            onStart={startRecording}
            onStop={stopRecording}
          />
        </>
      )}
    </VBox>
  )
}

export default AudioRecorder
