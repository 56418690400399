import React from 'react'
import type { RendererPlugin } from '../renderer.js'
import { isPositionEvent } from '@tunasong/schemas'
import { useTheme } from '@mui/material'

const Notch: RendererPlugin = ({ laneHeight, event, pixelsPerSecond }) => {
  const theme = useTheme()

  if (!isPositionEvent(event)) {
    return null
  }
  const x = event.start * pixelsPerSecond
  const y = laneHeight

  return <rect x={x} y={y} width={1} height={laneHeight} fill={theme.vars.palette.text.primary} />
}

export default Notch
